import React, { FC } from "react";
import { ReactComponent as DocumentIcon } from "../images/document.svg";
import { ReactComponent as BinIcon } from "../images/bin.svg";

interface IFileTabProps {
	name?: string;
	onBinClick: () => void;
	disabled?: boolean;
}

export const FileTab: FC<IFileTabProps> = (props: IFileTabProps) => (
	<div className="flex justify-between items-center mt-2">
		<div className="flex items-center">
			<DocumentIcon /> <span className="ml-1">{props.name}</span>
		</div>
		{!props.disabled && <BinIcon className="hover:cursor-pointer" onClick={props.onBinClick} />}
	</div>
);
