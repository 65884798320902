import axios, { AxiosRequestHeaders } from "axios";
import { API_BASE_URL } from "../constants";
import { authHeader } from "../security/secureClient";

export const fetchFile = (id: string | undefined, contentType: string | undefined): Promise<Blob> =>
	axios
		.get(`${API_BASE_URL}storage/${id}`, {
			headers: authHeader() as AxiosRequestHeaders,
			responseType: "blob",
		})
		.then(response => {
			const fileData: BlobPart = response.data as BlobPart;
			return new Blob([fileData], { type: contentType });
		})
		.catch(err => {
			console.debug(err);
			return new Blob();
		});
