import React, { FC, useEffect, useState } from "react";
import { Checkbox } from "antd";
import { ICheckboxOption, ILocation } from "../../common/model/IQuestionnaire";
import { getCurrentUser } from "../../security/securityService";
import "./QuestionnaireCheckbox.css";

const columnVariants = {
	1: "grid-cols-1",
	2: "grid-cols-2",
	3: "grid-cols-3",
	4: "grid-cols-4",
	5: "grid-cols-5",
	6: "grid-cols-6",
	7: "grid-cols-7",
	8: "grid-cols-8",
	9: "grid-cols-9",
	10: "grid-cols-10",
	11: "grid-cols-11",
	12: "grid-cols-12",
};

interface IQuestionnaireCheckboxProps {
	initialValue: string;
	label: string;
	name: string;
	options?: ICheckboxOption[];
	locations?: ILocation[];
	fillQuestionnaireValue: (fieldName: string, value: string) => void;
	setFormValid: (valid: boolean) => void;
	previousValue?: string;
}

interface IQuestionnaireLocationAnswer {
	locationId: string;
	locationName: string;
	locationUserId: string;
	isMainLocation: boolean;
	answers: IQuestionnaireCheckboxAnswer[];
}

interface IQuestionnaireCheckboxAnswer {
	name: string;
	selected: boolean;
}

export const QuestionnaireCheckbox: FC<IQuestionnaireCheckboxProps> = (props: IQuestionnaireCheckboxProps) => {
	const [answers, setAnswers] = useState([] as IQuestionnaireLocationAnswer[]);
	const [locations, setLocations] = useState([] as ILocation[]);
	const [options, setOptions] = useState([] as ICheckboxOption[]);

	useEffect(() => {
		setLocations(locationsToDisplay(props.locations || []));
		setOptions(sortedCheckboxOptions(props.options || []));
	}, []);

	useEffect(() => {
		if (props.previousValue) {
			setAnswers(JSON.parse(props.previousValue) as IQuestionnaireLocationAnswer[]);
		} else {
			setAnswers(initialValues());
		}
	}, [locations, options]);

	const locationsToDisplay = (locationList: ILocation[]): ILocation[] => {
		const role = getCurrentUser()?.role as string;
		if (["superAdmin", "releaseAdmin"].includes(role) && locationList.length === 0) {
			return [
				{ id: "1", name: "Testfiliale A", locationUserId: "1", isMainLocation: true },
				{ id: "2", name: "Testfiliale B", locationUserId: "2", isMainLocation: false },
				{ id: "3", name: "Testfiliale C", locationUserId: "3", isMainLocation: false },
			];
		}
		return locationList;
	};

	const sortedCheckboxOptions = (checkboxOptions: ICheckboxOption[]): ICheckboxOption[] =>
		checkboxOptions.sort((a, b) => {
			if (a.sortOrder === b.sortOrder) {
				return a.label.localeCompare(b.label);
			} else {
				return a.sortOrder - b.sortOrder;
			}
		});

	useEffect(() => {
		props.setFormValid(answers.filter(ans => ans.answers.filter(opt => opt.selected).length > 0).length > 0);
		props.fillQuestionnaireValue(props.name, JSON.stringify(answers));
	}, [answers]);

	const getValue = (location: ILocation, option: ICheckboxOption): boolean => {
		const result = answers.find(loc => loc.locationId === location.id)?.answers.find(ans => ans.name === option.name);

		return result ? result.selected : false;
	};

	const setSelectedValue = (location: ILocation, option: ICheckboxOption): void => {
		const currentSelection = !getValue(location, option);

		const locationExists = answers.filter(ans => ans.locationId === location.id).length > 0;

		if (!locationExists) {
			const newLocationAnswer = {
				locationId: location.id,
				locationName: location.name,
				locationUserId: location.locationUserId,
				isMainLocation: location.isMainLocation,
				answers: [
					{
						name: option.name,
						selected: currentSelection,
					},
				],
			};
			setAnswers([...answers, newLocationAnswer]);
		} else {
			setAnswers(
				answers.map(ans => {
					if (ans.locationId === location.id) {
						const optionExists = ans.answers.filter(opt => opt.name === option.name).length > 0;

						if (!optionExists) {
							const newOptionAnswer = { name: option.name, selected: currentSelection };

							return { ...ans, answers: [...ans.answers, newOptionAnswer] };
						} else {
							const newOptionAnswer = ans.answers.map(opt => {
								if (opt.name === option.name) {
									return { ...opt, selected: currentSelection };
								} else {
									return opt;
								}
							});
							return { ...ans, answers: newOptionAnswer };
						}
					} else {
						return ans;
					}
				})
			);
		}
	};

	const initialValues = (): IQuestionnaireLocationAnswer[] =>
		locations.map(loc => ({
			locationId: loc.id,
			locationName: loc.name,
			locationUserId: loc.locationUserId,
			isMainLocation: loc.isMainLocation,
			answers: options.map(opt => ({
				name: opt.name,
				selected: false,
			})),
		}));

	return (
		<div className="flex flex-col items-start">
			<span className="mb-2">{props.label}</span>
			<div className={`grid bg-white divide-y divide-slate-200 ${columnVariants[options.length + 1]}`}>
				<div className="border-t border-slate-200"></div>
				{options.map((option, index) => (
					<div className="py-2 grid justify-center font-extrabold break-all xl:break-normal" key={`opt-${index}`}>
						{option.label}
					</div>
				))}
				{locations.map((location, locIndex) => (
					<React.Fragment key={`loc-${locIndex}`}>
						<div className="pl-2 grid items-center break-all xl:break-normal">{location.name}</div>
						{options.map((option, index) => (
							<div className="py-2 grid justify-center items-center" key={`loc-${locIndex}-${index}`}>
								<Checkbox
									className="w-auto"
									type="checkbox"
									name={props.name}
									checked={getValue(location, option)}
									onChange={() => setSelectedValue(location, option)}
								/>
							</div>
						))}
					</React.Fragment>
				))}
			</div>
		</div>
	);
};
