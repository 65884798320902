import React, { FC, useState } from "react";
import { AxiosError } from "axios";
import { IBasicProps } from "../../IBasicProps";
import { ReactComponent as LeftArrow } from "../../images/chevron-left.svg";
import { getCurrentUser } from "../../security/securityService";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { sendRecertifyCancel } from "../../common/service/contractService";
import { handleError } from "../../common/notificationService";
import { ContractState } from "../../contractList/Contract";
import { RecertificationState } from "./model/ContractDetailsProjection";

interface IContractBackButtonProps extends IBasicProps {
	contractId?: string;
	isQuestionnaireView?: boolean;
	recertificationState: RecertificationState;
	approvalState?: ContractState;
	onFetchContractDetails: () => void;
	asButton: boolean;
	backLabel?: string;
}
export const ContractBackButton: FC<IContractBackButtonProps> = props => {
	const [showConfirmationDialog, setConfirmationDialog] = useState(false);
	const goBack = (): void => {
		if (props.contractId && recertificationInProgress()) {
			sendRecertifyCancel(props.contractId)
				.then(() => {
					props.onFetchContractDetails();
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		} else {
			const currentUser = getCurrentUser();

			if (["financialAdvisor", "regionalManager", "confirmationAdmin"].includes(currentUser?.role as string)) {
				if (props.isQuestionnaireView) {
					if (["financialAdvisor"].includes(currentUser?.role as string)) {
						if (props.approvalState === "NOT_CONFIRMED" || !props.approvalState) {
							props.navigateTo(`contracts`);
						} else {
							props.navigateTo(`contracts/${props.contractId}/details`);
						}
					} else {
						props.navigateTo(`contracts/${props.contractId}/details`);
					}
				} else {
					props.navigateTo(`contracts`);
				}
			} else if (
				currentUser?.role === "superAdmin" ||
				currentUser?.role === "editor" ||
				currentUser?.role === "releaseAdmin"
			) {
				props.navigateTo(`contracts/${props.contractId}/edit`);
			}
		}
	};

	const onCLick = (): void => {
		if (props.approvalState === "CONFIRMED" || props.approvalState === "EXPIRED") {
			if (!props.isQuestionnaireView && recertificationInProgress()) {
				setConfirmationDialog(true);
			} else if (props.isQuestionnaireView) {
				setConfirmationDialog(true);
			} else {
				goBack();
			}
		} else {
			goBack();
		}
	};

	const recertificationInProgress = (): boolean =>
		props.recertificationState === "PROCESSING" && ["financialAdvisor"].includes(getCurrentUser()?.role as string);

	return (
		<>
			{showConfirmationDialog && (
				<ConfirmationDialog
					t={props.t}
					onConfirm={goBack}
					onClose={() => setConfirmationDialog(false)}
					headerText={props.t("recertify.back.header")}
					messageText={props.t("recertify.back.alert")}
					confirmButtonText={props.t("yes")}
					cancelButtonText={props.t("no")}
				/>
			)}
			{props.asButton && (
				<button className="white-with-icon" onClick={onCLick}>
					<LeftArrow className="fill-bright-blue" />
					<span>{props.t("back")}</span>
				</button>
			)}
			{!props.asButton && (
				<div className="contract-details-return-tab" onClick={onCLick}>
					<LeftArrow className="-translate-y-0.5" />
					<h2>{props.backLabel}</h2>
				</div>
			)}
		</>
	);
};
