import React, { FC } from "react";
import "./Header.css";
import { IBasicProps } from "../IBasicProps";
import { Logo } from "../utils/Logo";
import { ReactComponent as LogoIcon } from "../images/logo.svg";
import { UserHeader } from "./UserHeader";
import { AdminHeader } from "./AdminHeader";

export interface IHeaderProps extends IBasicProps {
	postLogoutAction: (a: any) => any;
}

export const Header: FC<IHeaderProps> = (props: IHeaderProps) => (
	<header className="header">
		<div className="header-box-left">
			<div className="ml-5 hover:cursor-pointer">
				<Logo {...props} onClick={() => props.navigate("/contractapprovalmanager")} />
			</div>
			<AdminHeader {...props} />
		</div>
		<div className="header-box-right">
			<UserHeader {...props} />
			<LogoIcon className="h-10 m-3 inline" />
		</div>
	</header>
);
