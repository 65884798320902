import React, { FC, ReactElement, useEffect, useState } from "react";
import { Tooltip } from "antd";
import { ReactComponent as ClockIcon } from "../images/clock.svg";
import { ReactComponent as FileIcon } from "../images/file.svg";
import { ReactComponent as InactiveIcon } from "../images/inactive.svg";
import { ReactComponent as InvalidIcon } from "../images/invalid.svg";
import { ReactComponent as TickIcon } from "../images/tick.svg";
import { ReactComponent as UserIcon } from "../images/user.svg";
import { capitalize, getFormattedDateAndTimeWithoutSeconds } from "../utils/CustomFunctions";
import { IBasicProps } from "../IBasicProps";
import "./ContractCreatorReleaseStateLabel.css";
import { getEventNameBasedOnReleaseState, getLatestEventForType } from "../common/service/contractService";
import { LatestReleaseEvent } from "./model/LatestReleaseEvent";

interface IContractCreatorReleaseStateLabelProps extends IBasicProps {
	releaseState: string;
	contractId?: string;
}

export const ContractCreatorReleaseStateLabel: FC<IContractCreatorReleaseStateLabelProps> = (
	props: IContractCreatorReleaseStateLabelProps
) => {
	const rs = props.releaseState.toLowerCase();
	const [latestReleaseEvent, setLatestReleaseEvent] = useState<LatestReleaseEvent>();

	useEffect(() => {
		const eventType = getEventNameBasedOnReleaseState(props.releaseState);
		if (props.contractId && eventType !== "unknown") {
			getLatestEventForType(props.contractId, eventType)
				.then(r => setLatestReleaseEvent(r as LatestReleaseEvent))
				.catch(e => console.log(e));
		}
	}, [rs]);

	const getReleaseInfo = (): ReactElement => {
		if (latestReleaseEvent) {
			return (
				<>
					{props.t("status.changed")}: <br />
					{getFormattedDateAndTimeWithoutSeconds(new Date(latestReleaseEvent.date))} <br />
					{latestReleaseEvent?.user}
				</>
			);
		} else {
			return <>{props.t("status.not.found")}</>;
		}
	};

	return rs === "released" ? (
		<Tooltip placement="bottomRight" color={"white"} title={getReleaseInfo()} overlayInnerStyle={{ color: "black" }}>
			<span
				className={`block flex items-center text-base px-3 rounded-md hover:cursor-pointer contract-creator-release-state-label-${rs}`}
			>
				{capitalize(props.t("status"))}: {capitalize(props.t(`contract.release.state.${rs}`))}
				<TickIcon className="icon" fill="white" />
			</span>
		</Tooltip>
	) : (
		<Tooltip placement="bottomRight" color={"white"} title={getReleaseInfo()} overlayInnerStyle={{ color: "black" }}>
			<span
				className={`block flex items-center text-base px-3 rounded-md hover:cursor-pointer contract-creator-release-state-label-${rs}`}
			>
				{capitalize(props.t("status"))}: {capitalize(props.t(`contract.release.state.${rs}`))}
				{rs === "commissioned" && <ClockIcon className="icon" fill="white" />}
				{rs === "draft" && <FileIcon className="icon" fill="white" />}
				{rs === "inactive" && <InactiveIcon className="icon" fill="white" />}
				{rs === "invalid" && <InvalidIcon className="icon" fill="white" />}
				{rs === "to_be_released" && <UserIcon className="icon" fill="white" />}
			</span>
		</Tooltip>
	);
};
