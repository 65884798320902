import dateFormat from "dateformat";
import { User } from "../security/securityService";

export const capitalize = (text: string): string => {
	if (text !== null) {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	return "";
};

export const validateEmail = (email: string): boolean => {
	const emailSimpleRegex = /^\S+@\S+\.\S+$/;
	return emailSimpleRegex.test(email);
};

export const getUserFullName = (userLabel: string, user: User | null): string => {
	if (user === null) {
		return "";
	}

	return `${capitalize(user.firstname)} ${capitalize(user.lastname)} (${userLabel} ${user.financialNumber})`;
};

/* eslint-disable */
export const f2 = <I1 extends unknown, I2 extends unknown, O extends unknown>(a1: I1, a2: I2): O => {
	return {} as O;
}
export const v2 = <I1 extends unknown, I2 extends unknown>(a1: I1, a2: I2): void => {
	return;
}
export const getFormattedDate = (date: Date): string => dateFormat(new Date(date), "dd.mm.yyyy");

export const getFormattedDateAndTime = (date: Date): string => dateFormat(new Date(date), "dd.mm.yyyy HH:MM:ss");

export const getFormattedDateAndTimeWithoutSeconds = (date: Date): string => dateFormat(new Date(date), "dd.mm.yyyy HH:MM");
/* eslint-enable */
