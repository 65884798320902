import React, { FC, ReactNode } from "react";
import { IBasicProps } from "../IBasicProps";
import { WEB_BASE_URL } from "../constants";

interface IProtectedRouteProps extends IBasicProps {
	condition: boolean;
	children: ReactNode;
	message: string;
}

export const ProtectedRoute: FC<IProtectedRouteProps> = ({ condition, children, message, t, navigate }) => {
	if (condition) {
		return <>{children}</>;
	} else {
		return (
			<div className="flex flex-col pt-20 gap-4">
				<div className="flex justify-center items-center h-full w-full">
					{message !== undefined && message.trim().length > 0 ? (
						<span className="text-center text-xl">{message}</span>
					) : (
						<span className="text-center text-xl">{t("page.cannot.be.displayed")}</span>
					)}
				</div>
				<div className="flex items-center justify-center">
					<button className="primary h-8" onClick={() => navigate(`${WEB_BASE_URL}`)}>
						{t("back.to.homepage")}
					</button>
				</div>
			</div>
		);
	}
};
