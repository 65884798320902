import React, { FC, Suspense, useMemo } from "react";
import { Loader } from "./Loader";

interface IAntdIconProps {
	name?: string;
	className?: string;
	size: string;
}

export const AntdIcon: FC<IAntdIconProps> = ({ name, className, size }) => {
	const AntIcon = useMemo(
		() =>
			React.lazy(() =>
				import(`@ant-design/icons/es/icons/${name}.js`).catch(
					() => import(`@ant-design/icons/es/icons/SnippetsOutlined.js`)
				)
			),
		[]
	);

	return (
		<Suspense fallback={<Loader size={8} />}>
			<AntIcon className={className} style={{ fontSize: `${size}px` }} />
		</Suspense>
	);
};
