import React, { FC } from "react";
import "./AttachmentTab.css";

interface IAttachmentTabProps {
	name?: string;
	displayPdf: () => void;
	pdfId: string;
	activePdfId: string;
	index?: number;
	translate: (key: string) => string;
}

export const AttachmentTab: FC<IAttachmentTabProps> = (props: IAttachmentTabProps) => {
	const getLabel = (): string => {
		const name = props.name?.split("_", 1);
		return props.index ? `${props.translate("documents.attachment")} ${props.index}` : `${name}`;
	};
	const getStyle = (): string => (props.activePdfId === props.pdfId ? "active-attachment-tab" : "attachment-tab");

	return (
		<div className={getStyle()} onClick={props.displayPdf}>
			{getLabel()}
		</div>
	);
};
