import React, { FC } from "react";
import "./Logo.css";
import { IBasicProps } from "../IBasicProps";

export interface ILogoProps extends IBasicProps {
	onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
}

export const Logo: FC<ILogoProps> = (props: ILogoProps) => (
	<div onClick={evt => (props.onClick ? props.onClick(evt) : null)}>
		<div id="logo-text">
			<span id="logo-db">{props.t("db")}</span>
			<span id="logo-title">{props.t("title")}</span>
		</div>
	</div>
);
