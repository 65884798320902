import React, { FC, ReactNode } from "react";
import "./Breadcrumbs.css";
import { NavLink } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { IBasicProps } from "../IBasicProps";
import { WEB_BASE_URL } from "../constants";

export interface IBreadcrumbProps extends IBasicProps {
	prefix?: (index: number, size: number) => ReactNode;
	suffix?: (index: number, size: number) => ReactNode;
	separator?: string;
	contractId?: string;
	isAdmin?: boolean;
}

export const Breadcrumbs: FC<IBreadcrumbProps> = ({ prefix, suffix, separator, t, contractId, isAdmin }) => {
	const getRoutes = (): Record<string, string>[] => {
		if (contractId) {
			return [
				{ path: `${WEB_BASE_URL}`, breadcrumb: t("homepage") },
				{ path: `${WEB_BASE_URL}contracts`, breadcrumb: t("contracts") },
				{ path: `${WEB_BASE_URL}contracts/${contractId}/edit`, breadcrumb: t("add.contract") },
				{ path: `${WEB_BASE_URL}open-applications`, breadcrumb: t("menu.admin.open.contract") },
			];
		} else {
			return [
				{ path: `${WEB_BASE_URL}`, breadcrumb: t("homepage") },
				{ path: `${WEB_BASE_URL}contracts`, breadcrumb: t("contracts") },
				{ path: `${WEB_BASE_URL}contracts/create`, breadcrumb: t("add.contract") },
				{ path: `${WEB_BASE_URL}open-applications`, breadcrumb: t("menu.admin.open.contract") },
			];
		}
	};

	const getExcludedPaths = (): string[] => {
		if (isAdmin) {
			return ["/", `${WEB_BASE_URL}`, `${WEB_BASE_URL}contracts/${contractId}`];
		}
		return ["/"];
	};

	const breadcrumbs = useReactRouterBreadcrumbs(getRoutes(), {
		excludePaths: getExcludedPaths(),
	});

	return (
		<nav className="breadcrumb">
			{breadcrumbs.map(({ match, breadcrumb }, index, ts) => (
				<NavLink
					key={match.pathname}
					to={match.pathname}
					className={ts.length === index + 1 ? "breadcrumb-last" : "breadcrumb-element"}
				>
					<div className="breadcrumb-prefix">{prefix ? prefix(index, ts.length) : <></>}</div>
					<div className="breadcrumb-body">{breadcrumb}</div>
					<div className="breadcrumb-suffix"> {suffix ? suffix(index, ts.length) : <></>}</div>
					<div className="breadcrumb-separator"> {ts.length - 1 === index ? "" : separator ? separator : "/"}</div>
				</NavLink>
			))}
		</nav>
	);
};
