import React, { FC } from "react";
import { IBasicProps } from "../../IBasicProps";
import { ReactComponent as AlertIcon } from "../../images/alert.svg";
import { ContractDetailsCheckbox } from "./model/ContractDetailsCheckbox";

interface ICheckboxesUIProps extends IBasicProps {
	contractDetailsCheckboxes: ContractDetailsCheckbox[];
	setContractDetailsCheckboxes: (checkboxes: ContractDetailsCheckbox[]) => void;
	setShowCheckboxesCheckingRequiredWarning: (value: boolean) => void;
	showCheckboxesCheckingRequiredWarning: boolean;
}

export const ContractCheckboxes: FC<ICheckboxesUIProps> = (props: ICheckboxesUIProps) => {
	const changeIsCheckboxChecked = (key: string): void => {
		const newArr = props.contractDetailsCheckboxes;
		const checkbox = props.contractDetailsCheckboxes.filter(ch => ch.key === key);
		const index = props.contractDetailsCheckboxes.indexOf(checkbox[0], 0);
		newArr[index] = new ContractDetailsCheckbox(key, checkbox[0].value, !checkbox[0].isChecked);

		props.setContractDetailsCheckboxes(newArr);
		props.setShowCheckboxesCheckingRequiredWarning(false);
	};

	return (
		props.contractDetailsCheckboxes && (
			<div className="flex-col text-base text-justify w-9/12">
				{props.contractDetailsCheckboxes.map((checkbox, index) => (
					<div key={`con-det-checkbox-${index + 1}`} className="mb-1">
						<label className="hover:cursor-pointer flex items-start">
							<input
								type="checkbox"
								className="w-4 hover:cursor-pointer"
								onChange={() => changeIsCheckboxChecked(checkbox.key)}
							/>
							<span>{checkbox.value}</span>
						</label>
					</div>
				))}
				<div className="flex h-8 items-center mb-2">
					{props.showCheckboxesCheckingRequiredWarning && (
						<div className="flex justify-center items-center gap-2 w-fit mt-3 mb-3">
							<AlertIcon fill="red" className="h-4" />
							<span>{props.t("checkboxes.required.warning")}</span>
						</div>
					)}
				</div>
			</div>
		)
	);
};
