import React, { FC, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "../images/plus.svg";
import { ImageViewer } from "../common/ImageViewer";
import { ReactComponent as InfoIcon } from "../images/info.svg";
import { IBasicProps } from "../IBasicProps";

interface IPreviewImagePickerProps extends IBasicProps {
	previewImage?: File;
	setPreviewImage: (image?: File) => void;
	disabled?: boolean;
}

export const PreviewImagePicker: FC<IPreviewImagePickerProps> = (props: IPreviewImagePickerProps) => {
	const [incorrectImageType, setIncorrectImageType] = useState(false);
	const [isDragOver, setIsDragOver] = useState(false);

	const addPreviewImage = (image: File | undefined): void => {
		if (!props.disabled) {
			if (image !== undefined && ["image/png", "image/jpg", "image/jpeg"].includes(image.type) && image.size < 400000) {
				props.setPreviewImage(image);
				setIncorrectImageType(false);
			} else {
				props.setPreviewImage(undefined);
				setIncorrectImageType(true);
			}
		}
	};

	const inputPreviewImage = useRef<HTMLInputElement | null>(null);
	const previewImageClick = (): void => {
		if (inputPreviewImage.current !== null) {
			inputPreviewImage.current.click();
		}
	};

	const getImagePickerStyle = (): string => {
		if (props.disabled) {
			return "hover:cursor-not-allowed w-22 lg:w-44 xl:w-64";
		} else {
			if (isDragOver) {
				return "hover:cursor-pointer w-22 lg:w-44 xl:w-64 opacity-70";
			} else {
				return "hover:cursor-pointer w-22 lg:w-44 xl:w-64";
			}
		}
	};

	const getImageContainerStyle = (): string => {
		if (isDragOver && !props.disabled) {
			return "bg-white rounded-lg border-2 border-dashed border-green-400 h-48 lg:w-64";
		} else {
			return `rounded-lg border-2 border-dashed border-gray-400 ${
				props.disabled ? "bg-gray" : "bg-white"
			} h-48 lg:w-64`;
		}
	};

	return (
		<div>
			<input
				type="file"
				id="previewImageInput"
				ref={inputPreviewImage}
				style={{ display: "none" }}
				onChange={e => addPreviewImage(e.target.files?.[0])}
				disabled={props.disabled}
			/>
			<div className="w-4/5">
				<div className="mb-1">{props.t("thumbnail")}</div>
				{props.previewImage === undefined ? (
					<div
						className={getImageContainerStyle()}
						onDragOver={e => {
							e.preventDefault();
							setIsDragOver(true);
						}}
						onDrop={e => {
							addPreviewImage(e.dataTransfer.files[0]);
							e.preventDefault();
							setIsDragOver(false);
						}}
						onDragLeave={e => {
							e.preventDefault();
							setIsDragOver(false);
						}}
					>
						<div
							className={`flex flex-col h-full justify-center text-center ${
								props.disabled ? "cursor-not-allowed" : "hover:cursor-pointer"
							}`}
							onClick={() => previewImageClick()}
						>
							<>
								<PlusIcon className="mx-auto" fill="#9CA3AF" />
								<span className="text-gray-400">{props.t("drag.or.click.info")}</span>
								{incorrectImageType && (
									<span className="text-red text-xs p-2">{props.t("preview.image.limitations.info")}</span>
								)}
							</>
						</div>
					</div>
				) : (
					<div
						className={getImagePickerStyle()}
						onClick={() => previewImageClick()}
						onDragOver={e => {
							e.preventDefault();
							setIsDragOver(true);
						}}
						onDrop={e => {
							addPreviewImage(e.dataTransfer.files[0]);
							e.preventDefault();
							setIsDragOver(false);
						}}
						onDragLeave={e => {
							e.preventDefault();
							setIsDragOver(false);
						}}
					>
						<ImageViewer image={props.previewImage} />
					</div>
				)}
				<div className="flex mt-2 text-left justify-left xl:w-64">
					<InfoIcon className="w-12 h-6 mr-1 lg:w-6" fill="#0018A8" />
					<span>{props.t("wrong.image.provided.info")}</span>
				</div>
			</div>
		</div>
	);
};
