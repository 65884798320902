import React, { FC } from "react";
import "./Logout.css";
import { Navigate } from "react-router-dom";
import { IBasicProps } from "../IBasicProps";
import { SSO_URL, WEB_BASE_URL } from "../constants";
import { NotAuthView } from "./NotAuthView";

export const Logout: FC<IBasicProps> = (props: IBasicProps) => {
	if (props.authenticated) {
		return <Navigate to={`${WEB_BASE_URL}`} replace={true} />;
	}
	const login = (): any => (window.location.href = SSO_URL);

	return <NotAuthView action={login} showError={false} logoutButtonName={props.t("logout.back")} {...props} />;
};
