import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
	ContractExpirationDate,
	ContractExpired,
	ContractReleaseStateLabel,
	ContractStateLabel,
} from "../../contractList/ContractLabels";
import { IBasicProps } from "../../IBasicProps";
import { ContractState } from "../../contractList/Contract";
import "./Info.css";
import { AntdIcon } from "../../common/AntdIcon";
import { capitalize } from "../../utils/CustomFunctions";
import { CategoryProjection } from "./model/CategoryProjection";

interface IInfoProps extends IBasicProps {
	releaseState?: string;
	state: ContractState;
	category: CategoryProjection;
	interval?: number;
	infiniteInterval?: boolean;
	expirationDate?: Date;
	isInactive?: boolean;
}

export const Info: FC<IInfoProps> = (props: IInfoProps) => {
	const { t } = useTranslation();

	return (
		<div className="contract-info-container gap-20">
			<div className="flex-col">
				<p className="mb-1.5 whitespace-nowrap">{t("status")}</p>
				<div className="w-fit whitespace-nowrap pt-1">
					{props.isInactive ? (
						<ContractReleaseStateLabel releaseState={"INACTIVE"} {...props} />
					) : (
						<ContractStateLabel
							releaseState={props.releaseState}
							contractState={props.state}
							infiniteInterval={props.infiniteInterval}
							expirationDate={props.expirationDate}
							{...props}
						/>
					)}
				</div>
			</div>
			<div className="flex-col">
				<p className="whitespace-nowrap">{t("category")}</p>
				<div className="flex w-fit items-center gap-2">
					<AntdIcon name={props.category.iconName} className="w-5 h-5" size="20" />
					<span>{capitalize(props.category.value)}</span>
				</div>
			</div>
			{props.state && ["CONFIRMED", "BEING_REVIEWED", "APPROVED_UNTIL_DEADLINE"].includes(props.state) && (
				<div className="flex-col">
					<p className="whitespace-nowrap">{t("expirationDate")}</p>
					<div className="expiration-info">
						<ContractExpirationDate
							releaseState={props.releaseState}
							contractState={props.state}
							expirationDate={props.expirationDate}
							infiniteInterval={props.infiniteInterval}
							{...props}
						/>
					</div>
				</div>
			)}

			{props.state && ["EXPIRED"].includes(props.state) && (
				<div className="flex-col">
					<p className="whitespace-nowrap">{t("expirationDate")}</p>
					<div>
						<ContractExpired expirationDate={props.expirationDate} {...props} />
					</div>
				</div>
			)}
		</div>
	);
};
