import React, { FC } from "react";
import "./Logout.css";
import { IBasicProps } from "../IBasicProps";
import { ReactComponent as LogoIcon } from "../images/logo.svg";
import { ReactComponent as Alert } from "../images/alert.svg";
import { ReactComponent as ArrowRight } from "../images/arrow-right.svg";
import { Logo } from "../utils/Logo";
import { authError } from "./Sso";

interface INotAuthProps extends IBasicProps {
	action: () => any;
	showError?: boolean;
	logoutButtonName: string;
}

export const NotAuthView: FC<INotAuthProps> = props => (
	<div className="notauth-page">
		<div className="notauth-box bg-logout">
			<div className="notauth-tile">
				<div className="notauth-logo">
					<Logo {...props} />
					<LogoIcon className="h-10 m-2 inline" />
				</div>
				{props.showError && (
					<div className="notauth-message">
						<h3 className="flex pb-2 text-red">
							<Alert height="18" fill="red" />
							{props.t("error")}
						</h3>
						<div className="text-red">{authError !== null ? authError : props.t("logout.message")}</div>
					</div>
				)}
				<div className="py-3 px-7 text-sm">
					<button className="primary notauth-button" onClick={props.action}>
						<span className="mr-3">{props.logoutButtonName}</span>
						<span className="mr-3 pt-1">
							<ArrowRight className="fill-white" />
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
);
