import { Questionnaire } from "./Questionnaire";

export class GeneratePdfRequest {
	public reportKey: string;
	public values: Questionnaire[];

	constructor(reportKey: string, values: Questionnaire[]) {
		this.reportKey = reportKey;
		this.values = values;
	}
}
