import React, { FC, useState } from "react";
import { ReactComponent as PlusIcon } from "../images/plus.svg";
import { IBasicProps } from "../IBasicProps";
import { RequiredSign } from "../common/RequiredSign";

interface IDocumentUploaderProps extends IBasicProps {
	name?: string;
	onClick: () => void;
	onDrop: (e: React.DragEvent) => void;
	isMainFile?: boolean;
}

export const DocumentUploader: FC<IDocumentUploaderProps> = (props: IDocumentUploaderProps) => {
	const [isDragOver, setIsDragOver] = useState(false);

	const getContainerStyle = (): string => {
		const commonStyle =
			"flex bg-white rounded-lg h-16 border-2 border-dashed text-gray-400 justify-center items-center text-sm hover:cursor-pointer";

		if (isDragOver) {
			return `${commonStyle} border-green-400`;
		} else {
			return `${commonStyle} border-gray-400`;
		}
	};

	return (
		<div className="mt-4">
			<div className="mb-1 flex">
				{props.name}
				{props.isMainFile && <RequiredSign />}
			</div>
			<div
				className={getContainerStyle()}
				onClick={props.onClick}
				onDragOver={e => {
					e.preventDefault();
					setIsDragOver(true);
				}}
				onDrop={e => {
					props.onDrop(e);
					setIsDragOver(false);
				}}
				onDragLeave={() => setIsDragOver(false)}
			>
				<PlusIcon className="mr-3" fill="#9CA3AF" />
				<span>{props.t("document.uploader.info")}</span>
			</div>
		</div>
	);
};
