import { AxiosRequestHeaders } from "axios";
import { ACCESS_TOKEN } from "../constants";

export type Role =
	| ""
	| "financialAdvisor"
	| "regionalManager"
	| "editor"
	| "releaseAdmin"
	| "confirmationAdmin"
	| "superAdmin"
	| "Api"
	| undefined;

export class User {
	public id: string;
	public financialNumber: string;
	public locale: string;
	public salutation: string;
	public firstname: string;
	public lastname: string;
	public salesRegion: string;
	public role: Role;
	public visibilityGroups: string[];

	constructor(
		id: string,
		financialNumber: string,
		locale: string,
		salutation: string,
		firstname: string,
		lastname: string,
		salesRegion: string,
		role: string,
		visibilityGroups: string[]
	) {
		this.id = id;
		this.financialNumber = financialNumber;
		this.locale = locale;
		this.salutation = salutation;
		this.firstname = firstname;
		this.lastname = lastname;
		this.salesRegion = salesRegion;
		this.role = role as Role;
		this.visibilityGroups = visibilityGroups;
	}
}

export interface IAuthResponse {
	accessToken: string;
	user: User;
}

export interface IAuthHeader extends AxiosRequestHeaders {
	Authorization: string;
}

export const getCurrentUser = (): User | null => {
	const item = localStorage.getItem(ACCESS_TOKEN);
	if (item != null) {
		const token: IAuthResponse = JSON.parse(item || "{}") as IAuthResponse;
		return token.user;
	}
	return null as any;
};

export const checkPermissions = (allowedPermissions: string[]): boolean => {
	if (allowedPermissions.length === 0) {
		return true;
	}
	const currentUser = getCurrentUser();
	return allowedPermissions.includes(currentUser?.role as string);
};
