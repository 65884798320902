import React, { createContext, useState } from "react";
import { Questionnaire } from "../model/Questionnaire";

export interface IQuestionnaireContextType {
	filledContextQuestionnaire: Questionnaire[];
	setFilledContextQuestionnaire: (questionnaire: Questionnaire[]) => void;
}

export const QuestionnaireContext = createContext<IQuestionnaireContextType | null>(null);
export const QuestionnaireProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [filledContextQuestionnaire, setFilledContextQuestionnaire] = useState<Questionnaire[]>([]);
	return (
		<QuestionnaireContext.Provider value={{ filledContextQuestionnaire, setFilledContextQuestionnaire }}>
			{children}
		</QuestionnaireContext.Provider>
	);
};
