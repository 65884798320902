import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import deDE from "antd/lib/locale-provider/de_DE";
import { App } from "./App";
import "moment/locale/de";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<>
		<BrowserRouter>
			<ConfigProvider locale={deDE}>
				<App />
			</ConfigProvider>
		</BrowserRouter>
	</>
);
