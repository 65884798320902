import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { API_BASE_URL } from "../constants";
import { IAuthHeader, IAuthResponse } from "./securityService";
import { getAuthToken } from "./secureStorage";

export const authHeader = (): IAuthHeader => {
	const item = getAuthToken();
	if (item != null) {
		const token: IAuthResponse = JSON.parse(item || "{}") as IAuthResponse;
		return {
			Authorization: `Bearer ${token.accessToken}`,
		};
	}
	return {
		Authorization: "",
	};
};
export const post = (resource: string, data?: any, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<any>> =>
	axios.post(`${API_BASE_URL}${resource}`, data, {
		headers: authHeader() as AxiosRequestHeaders,
		...config,
	});
export const download = (
	filename: string,
	resource: string,
	data?: any,
	config?: AxiosRequestConfig<any>
): Promise<any> =>
	axios
		.post(`${API_BASE_URL}${resource}`, data, {
			headers: authHeader() as AxiosRequestHeaders,
			...config,
		})
		.then(response => {
			const headerLine = response.headers["content-disposition"]?.match(/filename=([^"]+)/);
			return fileDownload(response.data as Blob, headerLine?.length === 2 ? headerLine[1] : filename);
		});

export const get = (resource: string, config?: AxiosRequestConfig<any>): Promise<any> =>
	axios
		.get(`${API_BASE_URL}${resource}`, {
			headers: authHeader() as AxiosRequestHeaders,
			...config,
		})
		.then(response => response.data as string);
