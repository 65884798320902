import React, { FC, useState } from "react";
import { NavigateFunction, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { useTranslation } from "react-i18next";
import { Home } from "./home/Home";
import { Header } from "./header/Header";
import { IBasicProps } from "./IBasicProps";
import { ContractList } from "./contractList/ContractList";
import { ContractDetails } from "./contract/details/ContractDetails";
import { getCurrentUser } from "./security/securityService";
import { ContractCreator } from "./contractCreator/ContractCreator";
import { Logout } from "./security/Logout";
import { Sso } from "./security/Sso";
import { NotAuthView } from "./security/NotAuthView";
import { LOGOUT_URL, MOVE_MARKETING_REDIRECT_URL, SSO_URL, WEB_BASE_URL } from "./constants";

export let nav: NavigateFunction;

export const forceLogout = (): any => {
	const w = window.open(LOGOUT_URL, "logoff", "popup=true,width=1,height=1,top=0,left=0");
	setTimeout(() => {
		w?.close();
		window.location.href = SSO_URL;
	}, 3000);
};

export const redirectToMoveMarketing = (): any => {
	window.location.href = `${MOVE_MARKETING_REDIRECT_URL}`;
};

export const App: FC = () => {
	const currentUser = getCurrentUser();
	const [authenticated, setAuthenticated] = useState(currentUser != null);
	const [t] = useTranslation();
	const navigate: NavigateFunction = useNavigate();
	const navigateTo = (path: string): void => navigate(`${WEB_BASE_URL}${path}`);
	nav = navigate;
	const currentUserRole = currentUser?.role;

	const basicProps: IBasicProps = {
		authenticated,
		t,
		navigate,
		navigateTo,
		currentUserRole,
	};

	const shouldDisplayReleaseState = (): boolean =>
		currentUser?.role === "superAdmin" ||
		currentUser?.role === "editor" ||
		currentUser?.role === "releaseAdmin" ||
		currentUser?.role === "confirmationAdmin" ||
		currentUser?.role === "regionalManager";

	return (
		<>
			<Routes>
				<Route
					element={
						<>
							<Header postLogoutAction={setAuthenticated} {...basicProps} />
							<div className="outlet">
								<Outlet />
							</div>
						</>
					}
				>
					<Route path="/contractapprovalmanager" element={<Home {...basicProps} />} />
					<Route
						key="contracts"
						path="/contractapprovalmanager/contracts"
						element={
							<ContractList
								showFilterSelect={true}
								showReleaseState={shouldDisplayReleaseState()}
								showContractState={!shouldDisplayReleaseState()}
								showApplicationDate={true}
								showCategory={true}
								showUserName={false}
								showContractCreate={true}
								searchParameters={[["", ""]]}
								openEditPage={true}
								showGenerateReport={true}
								{...basicProps}
							/>
						}
					/>
					<Route
						key="open-applications"
						path="/contractapprovalmanager/open-applications"
						element={
							<ContractList
								showFilterSelect={false}
								showReleaseState={false}
								showContractState={false}
								showApplicationDate={false}
								showCategory={true}
								showUserName={true}
								searchParameters={[["releasestate", "to_be_released"]]}
								openEditPage={false}
								showContractsToApprove={true}
								showGenerateReport={false}
								{...basicProps}
							/>
						}
					/>
					<Route
						path="/contractapprovalmanager/contracts/:contractId/details/user/:userId"
						element={<ContractDetails isCurrentUser={false} {...basicProps} />}
					/>
					<Route
						path="/contractapprovalmanager/contracts/:contractId/details"
						element={<ContractDetails isCurrentUser={true} {...basicProps} />}
					/>
					<Route
						path="/contractapprovalmanager/contracts/:contractId/questionnaire"
						element={<ContractDetails isCurrentUser={true} isQuestionnaire={true} {...basicProps} />}
					/>
					<Route path="/contractapprovalmanager/contracts/create" element={<ContractCreator {...basicProps} />} />
					<Route
						path="/contractapprovalmanager/contracts/:contractId/edit"
						element={<ContractCreator {...basicProps} />}
					/>
				</Route>
				<Route
					path="/contractapprovalmanager/sso"
					element={<Sso {...basicProps} postLoginAction={setAuthenticated} />}
				/>
				<Route
					path="/contractapprovalmanager/auth-error"
					element={
						<NotAuthView
							action={(): any => {
								redirectToMoveMarketing();
							}}
							showError={true}
							logoutButtonName={t("redirect.to.move")}
							{...basicProps}
						/>
					}
				/>
				<Route path="/contractapprovalmanager/logout" element={<Logout {...basicProps} />} />
			</Routes>
		</>
	);
};
