import React, { FC, useEffect, useState } from "react";
import { Tooltip } from "antd";
import { IBasicProps } from "../IBasicProps";
import { ReactComponent as Singout } from "../images/signout.svg";
import { ReactComponent as List } from "../images/services.svg";
import { ReactComponent as UserIcon } from "../images/user.svg";
import { logout } from "../security/logoutService";
import { WEB_BASE_URL } from "../constants";
import { getCurrentUser, User } from "../security/securityService";
import { get } from "../security/secureClient";
import { getFormattedDateAndTime } from "../utils/CustomFunctions";
import { IHeaderProps } from "./Header";

const UserInfo: FC<IBasicProps> = () => {
	const currentUser = getCurrentUser() as User;
	return (
		<div className="flex text-xl font-normal items-center justify-center gap-2 border-r pr-3 border-bright-blue">
			<span className="inline-block m-auto text-bright-blue pt-1.5 text-sm lg:text-lg whitespace-nowrap">
				<span className="mr-3">
					<UserIcon height="16" className="translate-y-0.5" />
				</span>
				{currentUser?.firstname} {currentUser?.lastname}
			</span>
		</div>
	);
};

export const UserHeader: FC<IHeaderProps> = (props: IHeaderProps) => {
	if (!props.authenticated) {
		return <></>;
	}

	const [lastLogin, setLastLogin] = useState("Erstmalige Anmeldung");

	const logoutAction = (): void => {
		props.postLogoutAction(false);
		logout(() => props.navigate(`${WEB_BASE_URL}logout`));
	};

	useEffect(() => {
		get("auth/log")
			.then(data => {
				/* eslint-disable */
				if (data[0] !== undefined) {
					setLastLogin(getFormattedDateAndTime(data[0] as Date));
				}
				/* eslint-disable */
			})
			.catch(error => console.log(error));
	}, []);

	return (
		<div className="flex">
			<UserInfo {...props} />
			<div className="flex text-xl font-normal items-center justify-center gap-2 border-r pr-3 border-bright-blue pl-3 ml-0">
				<Tooltip title={`${props.t("login.last")}: ${lastLogin} Uhr`}>
					<List height="16" />
				</Tooltip>
			</div>
			<button
				className="flex text-xl font-normal items-center justify-center gap-2 pr-3 tertiary pl-3 ml-0"
				onClick={logoutAction}
			>
				<Singout height="16" />
			</button>
		</div>
	);
};
