import { ACCESS_TOKEN, LAST_UPDATE, MINUTES_30, WEB_BASE_URL } from "../constants";
import { nav } from "../App";
import { logout } from "./logoutService";

let timeout: NodeJS.Timeout;

const logoutBecauseTimeout = (): void => {
	clearTimeout(timeout);
	logout(() => nav(`${WEB_BASE_URL}logout`));
	return;
};

export const saveAuthToken = (token: string): void => {
	localStorage.setItem(ACCESS_TOKEN, token);
	localStorage.setItem(LAST_UPDATE, `${Date.now()}`);
	timeout = setTimeout(logoutBecauseTimeout, MINUTES_30);
	return;
};

export const getAuthToken = (): string | null => {
	const item = localStorage.getItem(ACCESS_TOKEN);
	const lastUpdate = localStorage.getItem(LAST_UPDATE);
	if (item !== null && lastUpdate !== null) {
		if (Date.now() - +lastUpdate < MINUTES_30) {
			localStorage.setItem(LAST_UPDATE, `${Date.now()}`);
			clearTimeout(timeout);
			timeout = setTimeout(logoutBecauseTimeout, MINUTES_30);
			return item;
		} else {
			logoutBecauseTimeout();
		}
	} else if (item !== null && lastUpdate === null) {
		localStorage.setItem(LAST_UPDATE, `${Date.now()}`);
		return item;
	}
	return item;
};

export const removeAuthToken = (): void => {
	localStorage.removeItem(LAST_UPDATE);
	localStorage.removeItem(ACCESS_TOKEN);
	clearTimeout(timeout);
	return;
};
