import { v4 } from "uuid";

export class ContractDetailsCheckbox {
	public key: string;
	public value: string;
	public isChecked: boolean;

	constructor(key: string, value: string, isChecked: boolean) {
		this.key = key;
		this.value = value;
		this.isChecked = isChecked;
	}
}

export const toContractDetailsCheckboxList = (values?: string[]): ContractDetailsCheckbox[] => {
	if (values === undefined) {
		return [];
	}

	const checkboxes: ContractDetailsCheckbox[] = [];

	values.map(value => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		checkboxes.push(new ContractDetailsCheckbox(v4().toString(), value, false));
	});

	return checkboxes;
};
