import React, { FC, ReactElement } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface ILoaderProps {
	size: number;
	coverBackground?: boolean;
}

const getLoader = (props: ILoaderProps): ReactElement => (
	<div className="absolute left-0 top-0 flex h-screen w-screen items-center justify-center">
		<Spin indicator={<LoadingOutlined style={{ fontSize: props.size }} spin />} />
	</div>
);

export const Loader: FC<ILoaderProps> = (props: ILoaderProps) => (
	<>
		{props.coverBackground ? (
			<div className="z-50 fixed left-0 top-0 h-screen w-screen bg-gray-500/50">{getLoader(props)}</div>
		) : (
			getLoader(props)
		)}
	</>
);

export const LoaderRelative: FC<ILoaderProps> = (props: ILoaderProps) => (
	<div className="z-50 absolute top-0 left-0 h-full w-full flex items-center justify-center">
		<Spin indicator={<LoadingOutlined style={{ fontSize: props.size }} spin />} />
	</div>
);
