import React, { FC } from "react";
import { IBasicProps } from "../IBasicProps";
import { checkPermissions, Role } from "../security/securityService";

interface IAccessControlProps extends IBasicProps {
	allowedPermission: Role[];
	children: React.ReactNode;
	renderNoAccess?: () => React.ReactNode;
}

export const AccessControl: FC<IAccessControlProps> = (props: IAccessControlProps) => {
	const permitted = checkPermissions(props.allowedPermission as string[]);
	const f = (): React.ReactNode => <></>;
	const renderNoAccess = props.renderNoAccess ?? f;

	if (permitted) {
		return <>{props.children}</>;
	}
	return <>{renderNoAccess()}</>;
};
