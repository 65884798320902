import React, { FC, ReactElement, useState, useContext } from "react";
import { Popconfirm } from "antd";
import { AxiosError } from "axios";
import Modal from "react-modal";
import { IBasicProps } from "../../IBasicProps";
import { ReactComponent as TickIcon } from "../../images/tick.svg";
import { ReactComponent as CrossIcon } from "../../images/cross.svg";
import { WEB_BASE_URL } from "../../constants";
import { AccessControl } from "../../accessControl/AccessControl";
import {
	generatePdf,
	sendApproveApproval,
	sendApproveContract,
	sendRevokeContract,
	sendRecertifyContract,
	sendRejectApproval,
	sendCancelContract,
} from "../../common/service/contractService";
import { ReactComponent as InfoIcon } from "../../images/info.svg";
import { handleError, notifySuccess } from "../../common/notificationService";
import { ContractState } from "../../contractList/Contract";
import { IHavingFields } from "../../common/model/IQuestionnaire";
import "./ContractDetailsButtons.css";
import { RecertificationState } from "./model/ContractDetailsProjection";
import { GeneratePdfRequest } from "./model/GeneratePdfRequest";
import { IQuestionnaireContextType, QuestionnaireContext } from "./context/QuestionnaireContext";
import { PdfOptions } from "./model/PdfOptions";

interface IContractDetailsButtonsProps extends IBasicProps {
	currentContractId?: string;
	contractState: ContractState;
	areAllRequiredDocumentsDownloaded: () => boolean;
	revocationPossible?: boolean;
	cancellationPossible: boolean;
	affectedUserId?: string;
	releaseNecessary: boolean;
	isQuestionnaireView?: boolean;
	recertificationState?: RecertificationState;
	questionnaire: IHavingFields;
	onFetchContractDetails: () => void;
	showGeneratePdfLoader: (value: boolean) => void;
	areAllCheckboxesChecked: () => boolean;
	shouldDisplayRecertifyButton: () => boolean;
	shouldDisplayButtonForNotConfirmedOrRevokedContract: () => boolean;
	setShowCheckboxesForRecertification: (value: boolean) => void;
}

export const ContractDetailsButtons: FC<IContractDetailsButtonsProps> = (props: IContractDetailsButtonsProps) => {
	const [rejectionModalOpen, setRejectionModalOpen] = useState(false);
	const [rejectionReason, setRejectionReason] = useState("");
	const [processing, setProcessing] = useState(false);
	const { filledContextQuestionnaire } = useContext(QuestionnaireContext) as IQuestionnaireContextType;

	const approveContract = (): void => {
		if (!processing && props.currentContractId && props.questionnaire?.reportKey) {
			setProcessing(true);
			generatePdf(
				props.currentContractId,
				new GeneratePdfRequest(props.questionnaire?.reportKey, filledContextQuestionnaire),
				new PdfOptions(true)
			)
				.then(() => {
					props.showGeneratePdfLoader(true);
					sendApproveContract(props.currentContractId as string)
						.then(() => {
							props.navigate(`${WEB_BASE_URL}contracts`);
							notifySuccess(
								props.t(
									props.releaseNecessary ? "contract.approved.released.successfully" : "contract.approved.successfully"
								)
							);
						})
						.catch((error: Error | AxiosError) => {
							handleError(error);
						})
						.finally(() => setProcessing(false));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				})
				.finally(() => {
					props.showGeneratePdfLoader(false);
				});
		}
	};

	const approveApproval = (): void => {
		if (props.currentContractId && props.affectedUserId) {
			sendApproveApproval(props.currentContractId, props.affectedUserId)
				.then(() => {
					gotoOpenApplications();
					notifySuccess(props.t("approval.approved.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		}
	};

	const approveContractEvent = (): void => {
		if (props.areAllCheckboxesChecked() && props.currentContractId && props.areAllRequiredDocumentsDownloaded()) {
			approveContract();
		}
	};

	const revokeContract = (): void => {
		if (props.currentContractId) {
			sendRevokeContract(props.currentContractId)
				.then(() => {
					gotoContracts();
					notifySuccess(props.t("contract.revoked.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		}
	};
	const cancelContract = (): void => {
		if (props.currentContractId) {
			sendCancelContract(props.currentContractId)
				.then(() => {
					gotoContracts();
					notifySuccess(props.t("contract.cancelled.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		}
	};

	const rejectApproval = (): void => {
		if (props.currentContractId && props.affectedUserId) {
			sendRejectApproval(props.currentContractId, rejectionReason, props.affectedUserId)
				.then(() => {
					gotoOpenApplications();
					notifySuccess(props.t("approval.rejected.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		}
	};

	const gotoContracts = (): void => {
		props.navigate(`${WEB_BASE_URL}contracts`);
	};

	const gotoOpenApplications = (): void => {
		props.navigate(`${WEB_BASE_URL}open-applications`);
	};

	const getRevokeButtonName = (): string => props.t("revoke.confirmation");

	const getRecertificationButtonName = (): string => {
		if (
			props.currentContractId &&
			(props.recertificationState === "NEW_DOCUMENT" || props.recertificationState === "RECERTIFIED")
		) {
			return props.t("recertify.start");
		} else {
			return props.t("recertify.now");
		}
	};

	const recertifyContract = (): void => {
		if (
			props.currentContractId &&
			(props.recertificationState === "NEW_DOCUMENT" || props.recertificationState === "RECERTIFIED")
		) {
			sendRecertifyContract(props.currentContractId)
				.then(() => {
					const hasQuestionnaireFields = props.questionnaire?.fields && props.questionnaire?.fields.length > 0;

					if (hasQuestionnaireFields) {
						props.navigate(`${WEB_BASE_URL}contracts/${props.currentContractId}/questionnaire`);
					} else {
						if (props.questionnaire?.reportKey && props.currentContractId) {
							props.showGeneratePdfLoader(true);
							generatePdf(
								props.currentContractId,
								new GeneratePdfRequest(props.questionnaire?.reportKey, []),
								new PdfOptions(false)
							)
								.then(() => {
									props.onFetchContractDetails();
								})
								.catch((error: Error | AxiosError) => {
									handleError(error);
								})
								.finally(() => {
									props.showGeneratePdfLoader(false);
								});
						}
					}
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		} else {
			props.setShowCheckboxesForRecertification(true);
			if (props.areAllCheckboxesChecked() && props.currentContractId && props.areAllRequiredDocumentsDownloaded()) {
				generatePdf(
					props.currentContractId,
					new GeneratePdfRequest(props.questionnaire?.reportKey, filledContextQuestionnaire),
					new PdfOptions(true)
				)
					.then(() => {
						props.showGeneratePdfLoader(true);
						sendRecertifyContract(props.currentContractId as string)
							.then(() => {
								props.navigate(`${WEB_BASE_URL}contracts`);
								notifySuccess(props.t("contract.recertified.successfully"));
							})
							.catch((error: Error | AxiosError) => {
								handleError(error);
							});
					})
					.catch((error: Error | AxiosError) => {
						handleError(error);
					})
					.finally(() => {
						props.showGeneratePdfLoader(false);
					});
			}
		}
	};

	const shouldDisplayRevokeButton = (): boolean =>
		props.currentContractId !== undefined &&
		props.contractState === "CONFIRMED" &&
		props.revocationPossible !== undefined &&
		props.revocationPossible;

	const shouldDisplayButtonsForAdmin = (): boolean =>
		props.currentContractId !== undefined && props.contractState === "BEING_REVIEWED";

	const getButtonsForNotConfirmedContract = (): ReactElement => (
		<div className="w-full">
			<AccessControl allowedPermission={["financialAdvisor"]} {...props}>
				<div className="text-white flex justify-end">
					{props.currentContractId && (
						<button className="primary-with-icon" onClick={() => approveContractEvent()}>
							<span>{props.t("confirm.now")}</span> <TickIcon fill="white" />
						</button>
					)}
				</div>
			</AccessControl>
		</div>
	);

	const getRevokeButton = (): ReactElement => (
		<Popconfirm
			placement="topRight"
			okText={props.t("yes.revoke")}
			cancelText={props.t("abort")}
			title={props.t("cancel.agreement.confirmation")}
			icon={<InfoIcon />}
			onConfirm={() => revokeContract()}
		>
			<button className="white-with-icon">
				<span>{getRevokeButtonName()}</span>
				<CrossIcon className="fill-bright-blue" />
			</button>
		</Popconfirm>
	);

	const shouldDisplayCancelButton = (): boolean => props.cancellationPossible;
	const getCancelButton = (): ReactElement => (
		<Popconfirm
			placement="topRight"
			okText={props.t("cancel.confirm")}
			cancelText={props.t("abort")}
			title={props.t("cancel.contract.confirmation")}
			icon={<InfoIcon />}
			onConfirm={() => cancelContract()}
		>
			<button className="white-with-icon">
				<span>{props.t("cancel.contract")}</span>
				<CrossIcon className="fill-bright-blue" />
			</button>
		</Popconfirm>
	);

	const getButtonsForAdmin = (): ReactElement => (
		<>
			<button className="white-with-icon" onClick={() => setRejectionModalOpen(true)}>
				<span>{props.t("reject")}</span> <CrossIcon className="fill-bright-blue" />
			</button>
			<Modal isOpen={rejectionModalOpen} className="flex flex-row justify-center items-center h-screen bg-black/70">
				<div className="w-1/4 shadow-xl border rounded-lg bg-white">
					<div className="flex items-center justify-between border-b p-1 border-gray-400">
						<h3 className="m-0 pl-2">{props.t("reject.contract")}</h3>
						<div className="pr-1">
							<CrossIcon
								onClick={() => {
									setRejectionModalOpen(false);
									setRejectionReason("");
								}}
								className="hover:cursor-pointer"
							/>
						</div>
					</div>
					<div className="border-b border-gray-400">
						<label className="flex flex-col m-6">
							<span>{props.t("reason.for.rejection")}</span>
							<textarea
								className="rounded border"
								rows={4}
								value={rejectionReason}
								onChange={e => setRejectionReason(e.target.value)}
							/>
						</label>
					</div>
					<div className="flex justify-end p-1 mr-2">
						<button
							className="tertiary-bordered"
							onClick={() => {
								setRejectionModalOpen(false);
								setRejectionReason("");
							}}
						>
							{props.t("abort")}
						</button>
						<button className="primary" onClick={() => rejectApproval()}>
							{props.t("reject")}
						</button>
					</div>
				</div>
			</Modal>

			<button className="primary-with-icon" onClick={() => approveApproval()}>
				<span>{props.t("approve")}</span> <TickIcon fill="white" />
			</button>
		</>
	);

	return !props.isQuestionnaireView ? (
		<div className="flex justify-between h-full items-end">
			<div className="flex flex-col w-full">
				<div className="flex justify-between w-full">
					{props.shouldDisplayButtonForNotConfirmedOrRevokedContract() ? (
						getButtonsForNotConfirmedContract()
					) : (
						<div className="flex justify-end w-full">
							<div className="flex gap-2">
								<AccessControl allowedPermission={["financialAdvisor"]} {...props}>
									{shouldDisplayRevokeButton() && getRevokeButton()}
									{shouldDisplayCancelButton() && getCancelButton()}
									{props.shouldDisplayRecertifyButton() && (
										<button className="primary-with-icon" onClick={() => recertifyContract()}>
											<span>{getRecertificationButtonName()}</span> <TickIcon fill="white" />
										</button>
									)}
								</AccessControl>
								<AccessControl allowedPermission={["superAdmin", "confirmationAdmin"]} {...props}>
									{shouldDisplayButtonsForAdmin() && getButtonsForAdmin()}
								</AccessControl>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};
