import React, { FC } from "react";
import { ReactComponent as BinIcon } from "../images/bin.svg";

interface ICheckboxCreatorProps {
	removeCheckbox: () => void;
	checkboxKey: string;
	checkboxValue: string;
	setValue: (key: string, value: string) => void;
	disabled?: boolean;
}

export const CheckboxCreator: FC<ICheckboxCreatorProps> = (props: ICheckboxCreatorProps) => (
	<div>
		{props.checkboxKey !== undefined && props.checkboxValue !== undefined && (
			<div className="flex items-center">
				<input
					className="w-full"
					type="text"
					name="checkbox"
					value={props.checkboxValue}
					onChange={e => props.setValue(props.checkboxKey, e.target.value)}
					disabled={props.disabled}
				/>
				{!props.disabled && <BinIcon className="hover:cursor-pointer ml-2" onClick={props.removeCheckbox} />}
			</div>
		)}
	</div>
);
