import { IQuestionnaire } from "../../../common/model/IQuestionnaire";

export class Questionnaire {
	public fieldName: string;
	public value: string;

	constructor(fieldName: string, value: string) {
		this.fieldName = fieldName;
		this.value = value;
	}
}

export const toQuestionnaire = (fetchedQuestionnaire?: IQuestionnaire): Questionnaire[] => {
	if (fetchedQuestionnaire === undefined || fetchedQuestionnaire.fields === undefined) {
		return [];
	}

	const questionnaires: Questionnaire[] = [];

	fetchedQuestionnaire.fields?.map(value => {
		questionnaires.push(new Questionnaire(value.name, value.initialValue));
	});

	return questionnaires;
};
