import React, { FC } from "react";
import "./Home.css";
import { Navigate } from "react-router-dom";
import { IBasicProps } from "../IBasicProps";
import { WEB_BASE_URL } from "../constants";
import { LinkTiles } from "./LinkTiles";

export const Home: FC<IBasicProps> = (props: IBasicProps) => {
	if (!props.authenticated) {
		return <Navigate to={`${WEB_BASE_URL}sso`} replace={true} />;
	}
	return <LinkTiles {...props} />;
};
