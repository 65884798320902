import React, { FC, useCallback, useEffect, useState } from "react";
import FileImageOutlined from "@ant-design/icons/FileImageOutlined";

interface IImageViewerProps {
	image: Blob;
}

export const ImageViewer: FC<IImageViewerProps> = ({ image }) => {
	const [imageUrl, setImageUrl] = useState("");
	const hasImage = image.size !== 0;

	const createUrl = useCallback(() => {
		setImageUrl(URL.createObjectURL(image));
	}, [image]);

	useEffect(() => {
		createUrl();
		return () => {
			URL.revokeObjectURL(imageUrl);
		};
	}, [createUrl]);

	const imageElement = <img src={imageUrl} className="w-full h-full max-h-48" alt="image" />;
	const fallbackElement = (
		<div className="flex items-center justify-center h-full">
			<FileImageOutlined className="w-24 text-6xl" />
		</div>
	);

	return <>{hasImage ? imageElement : fallbackElement}</>;
};
