import { v4 } from "uuid";

export class ContractCreatorCheckbox {
	public key: string;
	public value: string;

	constructor(key: string, value: string) {
		this.key = key;
		this.value = value;
	}
}

export const toContractCreatorCheckboxList = (values?: string[]): ContractCreatorCheckbox[] => {
	if (values === undefined) {
		return [];
	}

	const checkboxes: ContractCreatorCheckbox[] = [];

	values.map(value => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		checkboxes.push(new ContractCreatorCheckbox(v4().toString(), value));
	});

	return checkboxes;
};
