import React, { FC, useEffect, useState } from "react";
import { DatePicker, Radio, RadioChangeEvent } from "antd";
import moment from "moment";
import { IBasicProps } from "../IBasicProps";
import { DateRangePicker } from "../utils/DateRangePicker";

const DATE_FORMAT = "DD.MM.YYYY";
interface IContractReportDates extends IBasicProps {
	setReportParams?: ({}) => void;
	onFilterTypeChange?: (filterType: string) => void;
	onDateChange: ({}) => void;
}
export const ContractReportDates: FC<IContractReportDates> = (props: IContractReportDates) => {
	const [filterType, setFilterType] = useState("");
	const [date, setDate] = useState<moment.Moment | null>(moment());
	const onChange = (e: RadioChangeEvent): void => {
		setFilterType(e.target.value as string);
	};

	useEffect(() => {
		props.onDateChange({ date: date?.format(DATE_FORMAT) });
	}, []);

	useEffect(() => {
		props.onDateChange({ date: date?.format(DATE_FORMAT) });
	}, [date]);

	useEffect(() => {
		if (props.onFilterTypeChange) {
			props.onFilterTypeChange(filterType);
		}
	}, [filterType]);

	return (
		<Radio.Group onChange={onChange} value={filterType}>
			<div className="flex flex-row">
				<div>
					<Radio className="w-4 hover:cursor-pointer" name="report.contract" value="range">
						{props.t("report.change.period")}
					</Radio>
					{filterType !== "range" && <DateRangePicker dateFormat={DATE_FORMAT} {...props} disabled={true} />}
					{filterType === "range" && (
						<DateRangePicker
							dateFormat={DATE_FORMAT}
							{...props}
							onChange={(s, e) => {
								props.onDateChange({ start: s, end: e });
							}}
						/>
					)}
				</div>
				<div className="pr-20"></div>
				<div>
					<Radio className="w-4 hover:cursor-pointer" name="report.contract" value="deadline">
						{props.t("report.status.deadline")}
					</Radio>
					<div className="flex">
						<DatePicker
							format="DD.MM.YYYY"
							disabled={filterType !== "deadline"}
							className="single-date"
							placeholder={props.t("report.choose.date")}
							onChange={dt => setDate(dt)}
							value={date}
						/>
					</div>
				</div>
			</div>
		</Radio.Group>
	);
};
