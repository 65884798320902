import React, { FC } from "react";
import { IBasicProps } from "../IBasicProps";
import { ReactComponent as Cross } from "../images/cross.svg";
import { IVisibilityGroup } from "../common/model/IVisibilityGroup";

interface IChosenVisibilityGroupsProps extends IBasicProps {
	visibilityGroups: IVisibilityGroup[];
	chosenVisibilityGroups: string[];
	setVisibilityGroups: (vg: string[]) => void;
	disabled?: boolean;
}

export const ChosenVisibilityGroups: FC<IChosenVisibilityGroupsProps> = (props: IChosenVisibilityGroupsProps) => {
	const removeVisibilityGroup = (visibilityGroup: string): void => {
		const newArr = [...props.chosenVisibilityGroups];
		const index = props.chosenVisibilityGroups.indexOf(visibilityGroup);
		if (index > -1) {
			newArr.splice(index, 1);
		}

		props.setVisibilityGroups(newArr);
	};

	const getSpanStyle = (): string => {
		if (props.disabled) {
			return "pl-1 pr-1 rounded bg-white flex items-center justify-center gap-2 bg-[#E5E5E5] text-gray-400 border";
		} else {
			return "pl-1 pr-1 rounded bg-white flex items-center justify-center gap-2 border border-gray-400";
		}
	};

	return (
		<div className="flex gap-2 mb-2">
			{props.chosenVisibilityGroups.map((visibilityGroup, index) => (
				<div key={index} className={getSpanStyle()}>
					<span>{props.visibilityGroups.find(vg => vg.key === visibilityGroup)?.name}</span>
					{!props.disabled && (
						<Cross
							fill="#9CA3AF"
							className="w-2 hover:cursor-pointer"
							onClick={() => removeVisibilityGroup(visibilityGroup)}
						/>
					)}
				</div>
			))}
		</div>
	);
};
