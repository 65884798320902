import React, { FC } from "react";
import { validateEmail } from "../utils/CustomFunctions";
import { ReactComponent as Cross } from "../images/cross.svg";
import { IBasicProps } from "../IBasicProps";
import { notifyError } from "../common/notificationService";

interface IEmailProviderProps extends IBasicProps {
	emailAddresses: string[];
	setEmailAddresses: (addresses: string[]) => void;
	emailText: string;
	setEmailText: (text: string) => void;
	disabled?: boolean;
	singleEmail: string;
	setSingleEmail: (mail: string) => void;
	isEmailAddressNotConfirmed: boolean;
	setIsEmailAddressNotConfirmed: (confirmed: boolean) => void;
}

export const EmailProvider: FC<IEmailProviderProps> = (props: IEmailProviderProps) => {
	const addEmailAddress = (): void => {
		if (props.emailAddresses.length >= 20) {
			notifyError(props.t("too.many.emails.warning"));
			props.setSingleEmail("");
		} else if (!props.emailAddresses.includes(props.singleEmail.trim()) && validateEmail(props.singleEmail.trim())) {
			const newArr = [...props.emailAddresses, props.singleEmail.trim()];
			props.setEmailAddresses(newArr);
		}

		if (validateEmail(props.singleEmail.trim())) {
			props.setSingleEmail("");
			props.setIsEmailAddressNotConfirmed(false);
		} else if (props.singleEmail.length > 0) {
			props.setIsEmailAddressNotConfirmed(true);
		}
	};

	const addEmailAddressOnKeyDown = (key: string): void => {
		if (key === " " || key === "Enter") {
			addEmailAddress();
		}
	};

	const removeEmailAddress = (value: string): void => {
		const newArr = [...props.emailAddresses];
		const index = props.emailAddresses.indexOf(value);
		if (index > -1) {
			newArr.splice(index, 1);
		}

		props.setEmailAddresses(newArr);
	};

	const getEmailAddressInputStyle = (): string => {
		if (props.disabled) {
			return "mt-1 mb-2 p-1 h-8 flex border w-full rounded bg-[#E5E5E5] text-gray-400 gap-1 flex-wrap";
		} else {
			return "mt-1 mb-2 p-1 flex border w-full rounded bg-white gap-1 flex-wrap";
		}
	};

	const getEmailAddressSpanStyle = (): string => {
		if (props.disabled) {
			return "pl-1 pr-1 rounded bg-deutsche-gray flex items-center justify-center gap-2";
		} else {
			return "pl-1 pr-1 rounded bg-gray-200 flex items-center justify-center gap-2";
		}
	};

	return (
		<div className="flex flex-col">
			<span className="mb-2 mt-4">{props.t("email.notification")}</span>
			<div>
				<span>{props.t("recipient")}</span>
				<div className={getEmailAddressInputStyle()}>
					<>
						{props.emailAddresses.map((emailAddress, index) => (
							<div key={index} className={getEmailAddressSpanStyle()}>
								<span>{emailAddress}</span>{" "}
								{!props.disabled && (
									<Cross
										fill="#9CA3AF"
										className="w-2 hover:cursor-pointer"
										onClick={() => removeEmailAddress(emailAddress)}
									/>
								)}
							</div>
						))}
						{!props.disabled && (
							<div>
								<input
									id="emailInput"
									className="w-full border-0 m-0 max-h-6 outline-none"
									type="email"
									name="Empfanger"
									value={props.singleEmail}
									onChange={e => props.setSingleEmail(e.target.value)}
									onKeyDown={e => addEmailAddressOnKeyDown(e.key)}
									onBlur={() => addEmailAddress()}
								/>
							</div>
						)}
					</>
				</div>
				{props.isEmailAddressNotConfirmed && <span className="text-red">{props.t("email.not.confirmed")}</span>}
			</div>
			<label>
				<span>{props.t("email.info.text")}</span>
				<textarea
					className="disabled:bg-[#E5E5E5] disabled:text-gray-400 p-2"
					rows={4}
					value={props.emailText}
					onChange={e => props.setEmailText(e.target.value)}
					disabled={props.disabled}
				/>
			</label>
		</div>
	);
};
