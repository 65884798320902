import React, { FC, useEffect, useState } from "react";
import { IBasicProps } from "../IBasicProps";
import { getTiles } from "./homeService";

export const LinkTiles: FC<IBasicProps> = () => {
	const [tiles, setTiles] = useState("");
	const [styles, setStyles] = useState("");
	useEffect(() => {
		getTiles()
			.then((s: string) => {
				if ((s?.match(/href="(.*?)"/g)?.length || 0) > 4) {
					setStyles("three-cols");
				}
				setTiles(s);
			})
			.catch(err => console.log(err));
	}, []);

	return <div id="tiles" className={styles} dangerouslySetInnerHTML={{ __html: tiles }}></div>;
};
