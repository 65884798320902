import Modal from "react-modal";
import React, { FC } from "react";
import { ReactComponent as CrossIcon } from "../images/cross.svg";

interface IConfirmationDialogProps {
	t: (key: string) => string;
	onConfirm: () => void;
	onClose: () => void;
	headerText: string;
	messageText: string;
	confirmButtonText: string;
	cancelButtonText?: string;
}
export const ConfirmationDialog: FC<IConfirmationDialogProps> = props => (
	<Modal isOpen={true} className="flex flex-row justify-center items-center h-screen bg-black/70">
		<div className="w-1/4 shadow-xl border rounded-lg bg-white">
			<div className="flex items-center justify-between border-b p-1 border-gray-400">
				<h3 className="m-0 pl-2">{props.headerText}</h3>
				<div className="pr-1">
					<CrossIcon onClick={props.onClose} className="hover:cursor-pointer" />
				</div>
			</div>
			<div className="border-b border-gray-400">
				<label className="flex flex-col m-6">
					<span>{props.messageText}</span>
				</label>
			</div>
			<div className="flex justify-end p-1 mr-2">
				<button className="tertiary-bordered" onClick={props.onClose}>
					{props.cancelButtonText ? props.cancelButtonText : props.t("abort")}
				</button>
				<button
					className="primary"
					onClick={() => {
						props.onConfirm();
						props.onClose();
					}}
				>
					{props.confirmButtonText}
				</button>
			</div>
		</div>
	</Modal>
);
