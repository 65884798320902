import React, { FC, useEffect, useState } from "react";
import { IBasicProps } from "../../IBasicProps";
import "./Info.css";
import { getLatestEventForAffectedUser } from "../../common/service/contractService";
import { ReactComponent as CalendarIcon } from "../../images/calendar.svg";
import { ReactComponent as UserIcon } from "../../images/user.svg";
import { capitalize, getFormattedDate, getUserFullName } from "../../utils/CustomFunctions";
import { AntdIcon } from "../../common/AntdIcon";
import { EventProjection } from "./model/EventProjection";
import { CategoryProjection } from "./model/CategoryProjection";

interface IInfoProps extends IBasicProps {
	contractId?: string;
	affectedUserId?: string;
	category: CategoryProjection;
}

export const SubscriberInfo: FC<IInfoProps> = (props: IInfoProps) => {
	const [eventInfo, setEventInfo] = useState<EventProjection>();

	useEffect(() => {
		if (props.contractId && props.affectedUserId) {
			getLatestEventForAffectedUser(props.contractId, props.affectedUserId)
				.then(r => setEventInfo(r as EventProjection))
				.catch(e => console.log(e));
		}
	}, [props.contractId, props.affectedUserId]);

	return (
		<div className="flex mt-6 mb-10 gap-20">
			{eventInfo?.creationDate && (
				<div className="flex-col">
					<p className="mb-2">{props.t("date.of.receipt")}</p>
					<div className="flex items-center gap-2">
						<CalendarIcon className="w-5" />
						<span className="mt-0.5">{getFormattedDate(new Date(eventInfo?.creationDate))}</span>
					</div>
				</div>
			)}
			<div className="flex-col">
				<p className="mb-2">{props.t("category")}</p>
				<div className="flex items-center gap-2">
					<AntdIcon name={props.category.iconName} className="w-5 h-5" size="20" />
					<span>{capitalize(props.category.value)}</span>
				</div>
			</div>
			{eventInfo?.user && (
				<div className="flex-col">
					<p className="mb-2">{props.t("subscriber")}</p>
					<div className="flex items-center gap-2 -translate-y-0.5">
						<UserIcon className="w-4" />
						<span className="mt-0.5">{getUserFullName(props.t("user.id.label"), eventInfo.user)}</span>
					</div>
				</div>
			)}
		</div>
	);
};
