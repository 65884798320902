import React, { FC, useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment/moment";
import { IBasicProps } from "../IBasicProps";
import { v2 } from "./CustomFunctions";
import "./DateRangePicker.css";

interface IDatePickerProps extends IBasicProps {
	dateFormat: string;
	onChange?: (startDate: string | null, endDate: string | null) => void;
	disabled?: boolean;
}

export const DateRangePicker: FC<IDatePickerProps> = ({ dateFormat, t, onChange = v2, disabled }) => {
	const [startDate, setStartDate] = useState<moment.Moment | null>(moment().startOf("month"));
	const [endDate, setEndDate] = useState<moment.Moment | null>(moment());

	useEffect(() => {
		onChange(moment(startDate).format(dateFormat), moment(endDate).format(dateFormat));
	}, [startDate, endDate]);

	return (
		<div className="flex">
			<DatePicker
				format={dateFormat}
				disabled={disabled}
				className="start-date"
				placeholder={t("report.start.date")}
				onChange={dt => setStartDate(dt)}
				suffixIcon={<span>~</span>}
				value={startDate}
			/>
			<DatePicker
				format={dateFormat}
				disabled={disabled}
				className="end-date"
				placeholder={t("report.end.date")}
				onChange={dt => setEndDate(dt)}
				value={endDate}
			/>
		</div>
	);
};
