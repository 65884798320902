import { v4 } from "uuid";
import { UploadedFileProjection } from "../../contract/details/model/UploadedFileProjection";
import { fetchFile } from "../../file/downloadFileService";

export class ContractCreatorAttachment {
	public key: string;
	public value: File;

	constructor(key: string, value: File) {
		this.key = key;
		this.value = value;
	}
}

export const toContractCreatorAttachment = (values?: UploadedFileProjection[]): ContractCreatorAttachment[] => {
	if (values === undefined) {
		return [];
	}

	const attachments: ContractCreatorAttachment[] = [];

	values.map(value => {
		fetchFile(value.id, value.contentType)
			.then(response => {
				attachments.push(
					new ContractCreatorAttachment(v4(), new File([response], value.name, { type: value.contentType }))
				);
			})
			.catch(e => console.log(e));
	});

	return attachments;
};
