import React, { FC, ReactElement, useState } from "react";
import { AxiosError } from "axios";
import { Popconfirm, Tooltip } from "antd";
import { IBasicProps } from "../IBasicProps";
import { ReactComponent as CrossIcon, ReactComponent as Cross } from "../images/cross.svg";
import { ReactComponent as ArrowRight } from "../images/arrow-right.svg";
import { ReactComponent as LeftArrow } from "../images/chevron-left.svg";
import { ReactComponent as InfoIcon } from "../images/info.svg";
import { ReactComponent as BinIcon } from "../images/bin.svg";
import { WEB_BASE_URL } from "../constants";
import { ReleaseStatusChangeButton } from "../contract/ReleaseStatusChangeButton";
import { ReactComponent as TickIcon } from "../images/tick.svg";
import {
	sendRejectContract,
	sendReleaseContract,
	sendReleaseContractWithNotification,
} from "../common/service/contractService";
import "./ContractCreatorButtons.css";
import { handleError, notifySuccess } from "../common/notificationService";
import { ConfirmationDialog } from "../common/ConfirmationDialog";

interface IContractCreatorButtonsProps extends IBasicProps {
	contractId?: string;
	releaseState?: string;
	addContract: (releaseState: string) => void;
	updateContract: (releaseState: string) => void;
	deleteContract: () => void;
	isQuestionnaireUploaded?: boolean;
}

export const ContractCreatorButtons: FC<IContractCreatorButtonsProps> = (props: IContractCreatorButtonsProps) => {
	const [confirmationDialog, setConfirmationDialog] = useState(<></>);
	const isDraft = (): boolean => props.releaseState !== undefined && props.releaseState === "DRAFT";

	const isWaitingForRelease = (): boolean =>
		props.releaseState !== undefined && props.releaseState === "TO_BE_RELEASED";

	const isReleased = (): boolean => props.releaseState !== undefined && props.releaseState === "RELEASED";

	const isInactiveDeadline = (): boolean =>
		props.releaseState !== undefined && props.releaseState === "INACTIVE_DEADLINE";

	const isCommissioned = (): boolean => props.releaseState !== undefined && props.releaseState === "COMMISSIONED";

	const showDeactivateDialog = (): void => {
		setConfirmationDialog(
			<ConfirmationDialog
				t={props.t}
				onConfirm={() => {
					props.updateContract("INACTIVE");
				}}
				onClose={() => setConfirmationDialog(<></>)}
				headerText={props.t("contract.deactivate.deadline.modal.header")}
				messageText={props.t("contract.deactivate.deadline.modal.message")}
				confirmButtonText={props.t("contract.deactivate.deadline.modal.confirm")}
			/>
		);
	};

	const showDeactivateDeadlineDialog = (): void => {
		setConfirmationDialog(
			<ConfirmationDialog
				t={props.t}
				onConfirm={() => {
					props.updateContract("INACTIVE_DEADLINE");
				}}
				onClose={() => setConfirmationDialog(<></>)}
				headerText={props.t("contract.deactivate.deadline.modal.header")}
				messageText={props.t("contract.deactivate.deadline.modal.message")}
				confirmButtonText={props.t("contract.deactivate.deadline.modal.confirm")}
			/>
		);
	};

	const showInvalidateDialog = (): void => {
		setConfirmationDialog(
			<ConfirmationDialog
				t={props.t}
				onConfirm={() => {
					props.updateContract("INVALID");
				}}
				onClose={() => setConfirmationDialog(<></>)}
				headerText={props.t("contract.invalid.modal.header")}
				messageText={props.t("contract.deactivate.deadline.modal.message")}
				confirmButtonText={props.t("contract.invalid.modal.confirm")}
			/>
		);
	};

	const releaseContract = (): void => {
		if (props.contractId) {
			sendReleaseContract(props.contractId)
				.then(() => {
					props.navigate(`${WEB_BASE_URL}contracts`);
					notifySuccess(props.t("contract.approved.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		}
	};

	const releaseContractWithNotification = (): void => {
		if (props.contractId) {
			sendReleaseContractWithNotification(props.contractId)
				.then(() => {
					props.navigate(`${WEB_BASE_URL}contracts`);
					notifySuccess(props.t("contract.approved.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		}
	};

	const rejectContract = (): void => {
		if (props.contractId) {
			sendRejectContract(props.contractId)
				.then(() => {
					props.navigate(`${WEB_BASE_URL}contracts`);
					notifySuccess(props.t("contract.rejected.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				});
		}
	};

	const getButtonsForContractCreate = (): ReactElement => (
		<>
			<button className="white-with-icon ml-8" onClick={() => props.navigate(`${WEB_BASE_URL}contracts`)}>
				<Cross className="fill-bright-blue" />
				<span>{props.t("abort")}</span>
			</button>
			<div className="flex mr-8 gap-2">
				<button className="white-with-icon" onClick={() => props.addContract("DRAFT")}>
					<span>{props.t("save.as.draft")}</span>
				</button>
				<button className="primary-with-icon" onClick={() => props.addContract("COMMISSIONED")}>
					<span>{props.t("commission.questionnaire")}</span>
					<ArrowRight fill="white" />
				</button>
			</div>
		</>
	);

	const getButtonsForContractDraft = (): ReactElement => (
		<>
			<div className="flex ml-8 gap-2">
				<button className="white-with-icon" onClick={() => props.navigate(`${WEB_BASE_URL}contracts`)}>
					<Cross className="fill-bright-blue" />
					<span>{props.t("abort")}</span>
				</button>
				<button className="warning-with-icon" onClick={() => props.deleteContract()}>
					<BinIcon fill="red" />
					<span>{props.t("delete.draft")}</span>
				</button>
			</div>
			<div className="flex mr-8 gap-2">
				<button className="white-with-icon" onClick={() => props.updateContract("DRAFT")}>
					<span>{props.t("save.as.draft")}</span>
				</button>
				<button className="primary-with-icon" onClick={() => props.updateContract("COMMISSIONED")}>
					<span>{props.t("commission.questionnaire")}</span>
					<ArrowRight fill="white" />
				</button>
			</div>
		</>
	);

	const getButtonsForContractToBeReleased = (contractId: string): ReactElement => (
		<>
			{getGoBackButton()}
			<div className="flex mr-8 gap-2">
				{getTestButton(contractId)}
				<ReleaseStatusChangeButton contractId={contractId} targetState="draft" {...props}>
					<button className="red-with-icon" onClick={() => rejectContract()}>
						<span>{props.t("reject")}</span> <CrossIcon fill="white" />
					</button>
				</ReleaseStatusChangeButton>
				<ReleaseStatusChangeButton contractId={contractId} targetState="released" {...props}>
					<Popconfirm
						placement="topRight"
						title={props.t("should.notify.user.info")}
						cancelText={props.t("no")}
						okText={props.t("yes")}
						icon={<InfoIcon />}
						onConfirm={() => releaseContractWithNotification()}
						onCancel={() => releaseContract()}
					>
						<button className="green-with-icon">
							<span>{props.t("approve")}</span> <TickIcon fill="white" />
						</button>
					</Popconfirm>
				</ReleaseStatusChangeButton>
			</div>
		</>
	);

	const getButtonsForContractReleasedOrInactiveDeadline = (contractId: string): ReactElement => (
		<>
			{confirmationDialog}
			<div className="flex ml-8 gap-2">
				<button className="white-with-icon" onClick={() => props.navigate(`${WEB_BASE_URL}contracts`)}>
					<Cross className="fill-bright-blue" />
					<span>{props.t("abort")}</span>
				</button>
				<ReleaseStatusChangeButton contractId={contractId} targetState="inactive" {...props}>
					<Tooltip
						placement="top"
						color={"white"}
						title={props.t("contract.deactivate.alert")}
						overlayInnerStyle={{ color: "black" }}
					>
						<button className="warning" onClick={showDeactivateDialog}>
							{props.t("deactivate")}
						</button>
					</Tooltip>
				</ReleaseStatusChangeButton>
				<ReleaseStatusChangeButton contractId={contractId} targetState="inactive_deadline" {...props}>
					<Tooltip
						placement="top"
						color={"white"}
						title={props.t("contract.deactivate.deadline.alert")}
						overlayInnerStyle={{ color: "black" }}
					>
						<button className="warning" onClick={showDeactivateDeadlineDialog}>
							{props.t("deactivate.deadline")}
						</button>
					</Tooltip>
				</ReleaseStatusChangeButton>
				<ReleaseStatusChangeButton contractId={contractId} targetState="invalid" {...props}>
					<Tooltip
						placement="top"
						color={"white"}
						title={props.t("contract.invalid.alert")}
						overlayInnerStyle={{ color: "black" }}
					>
						<button className="warning" onClick={showInvalidateDialog}>
							{props.t("invalid")}
						</button>
					</Tooltip>
				</ReleaseStatusChangeButton>
			</div>
			<div className="flex mr-8 gap-2">
				<ReleaseStatusChangeButton contractId={contractId} targetState="released" {...props}>
					<button className="primary h-10" onClick={() => props.updateContract(props.releaseState || "RELEASED")}>
						{props.t("save.changes")}
					</button>
				</ReleaseStatusChangeButton>
			</div>
		</>
	);

	const getButtonsForContractCommissioned = (contractId: string): ReactElement => (
		<>
			{getGoBackButton()}
			<div className="flex mr-8 gap-2">{getTestButton(contractId)}</div>
		</>
	);

	const getGoBackButton = (): ReactElement => (
		<div className="flex ml-8 gap-2">
			<button className="white-with-icon" onClick={() => props.navigate(`${WEB_BASE_URL}contracts`)}>
				<LeftArrow className="fill-bright-blue" />
				<span>{props.t("back")}</span>
			</button>
		</div>
	);

	const getTestButton = (contractId: string): ReactElement =>
		props.isQuestionnaireUploaded ? (
			<button
				className="white-with-icon"
				onClick={() => props.navigate(`${WEB_BASE_URL}contracts/${contractId}/questionnaire`)}
			>
				<span>{props.t("test")}</span>
			</button>
		) : (
			<Tooltip
				placement="bottomRight"
				color={"black"}
				title={props.t("contract.test.unavailable.message")}
				overlayInnerStyle={{ color: "white" }}
			>
				<button className="disabled-bordered">
					<span>{props.t("test")}</span>
				</button>
			</Tooltip>
		);

	return (
		<div className="w-full h-20 shadow-lg shadow-gray-900 flex justify-between items-center">
			{props.contractId === undefined && getButtonsForContractCreate()}
			{props.contractId && isDraft() && getButtonsForContractDraft()}
			{props.contractId && isWaitingForRelease() && getButtonsForContractToBeReleased(props.contractId)}
			{props.contractId &&
				(isReleased() || isInactiveDeadline()) &&
				getButtonsForContractReleasedOrInactiveDeadline(props.contractId)}
			{props.contractId && isCommissioned() && getButtonsForContractCommissioned(props.contractId)}
			{props.contractId &&
				!isDraft() &&
				!isWaitingForRelease() &&
				!(isReleased() || isInactiveDeadline()) &&
				!isCommissioned() &&
				getGoBackButton()}
		</div>
	);
};
