import React, { FC, useEffect, useState } from "react";
import { AxiosError } from "axios";
import Modal from "react-modal";
import "./Reports.css";
import { Select } from "react-functional-select";
import { useMediaQuery } from "@react-hook/media-query";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import { ReactComponent as CloseIcon } from "../images/cross.svg";
import { IBasicProps } from "../IBasicProps";
import { download, get } from "../security/secureClient";
import { handleError, notifySuccess } from "../common/notificationService";
import { ContractReportDates } from "./ContractReportDates";

interface IReport {
	name: string;
	displayName: string;
	withName?: boolean;
}

interface IReportRequest {
	name?: string;
	filterType?: string;
	date?: Date;
	start?: Date;
	end?: Date;
}

export const Report: FC<IBasicProps> = ({ t, ...props }) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [isGenerateDisable, setGenerateDisable] = useState(false);
	const [children, setChildren] = useState(<></>);
	const [reports, setReports] = useState<IReport[]>([]);
	const [reportDates, setReportDates] = useState({});
	const [reportFilterType, setReportFilterType] = useState("");
	const [reportRequest, setReportRequest] = useState<IReportRequest>({});
	const isLargeScreen = useMediaQuery("(min-width: 1024px)");
	const getOptionValue = (opt: IReport): string => opt.name;
	const getOptionLabel = (opt: IReport): string => opt.displayName || t(opt.name);

	useEffect(() => {
		setReportRequest({ ...reportRequest, ...reportDates, filterType: reportFilterType });
	}, [reportDates, reportFilterType]);

	const onOptionChange = (opt: IReport | null): void => {
		setReportRequest({ ...reportRequest, name: opt?.name });

		setChildren(
			<ContractReportDates
				key={new Date().getMilliseconds()}
				onDateChange={setReportDates}
				onFilterTypeChange={setReportFilterType}
				{...props}
				t={t}
			/>
		);
	};

	const openReportSelection = (): void => {
		setChildren(<></>);
		setReportRequest({});
		setGenerateDisable(false);
		setIsOpen(true);
	};
	const closeReportSelection = (): void => {
		setIsOpen(false);
	};

	const generateReportName = (): string => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		const hour = String(currentDate.getHours()).padStart(2, "0");
		const minute = String(currentDate.getMinutes()).padStart(2, "0");

		if (reportRequest && reportRequest.name === "report.status") {
			return `status_report_${year}-${month}-${day}_${hour}-${minute}.xlsx`;
		} else if (reportRequest && reportRequest.name === "report.change") {
			return `change_report_${year}-${month}-${day}_${hour}-${minute}.xlsx`;
		} else {
			return `contract_report_${year}-${month}-${day}_${hour}-${minute}.xlsx`;
		}
	};

	const generateReport = (): void => {
		setGenerateDisable(true);
		download(generateReportName(), "reports", reportRequest, {
			responseType: "blob",
		})
			.then(() => {
				closeReportSelection();
				setGenerateDisable(false);
				notifySuccess(t("report.generated.successfully"));
			})
			.catch((error: Error | AxiosError) => {
				handleError(error);
				setGenerateDisable(false);
			});
	};

	useEffect(() => {
		if (modalIsOpen) {
			get("reports")
				.then(rs => setReports(rs as IReport[]))
				.catch(e => {
					handleError(e as AxiosError | Error);
					setReports([]);
				});
		}
	}, [modalIsOpen]);

	return (
		<>
			{isLargeScreen ? (
				<button className="open-report white h-8" onClick={openReportSelection}>
					<span>{t("report.generate")}</span>
					<DownloadIcon className="fill-bright-blue" />
				</button>
			) : (
				<button className="open-report white" onClick={openReportSelection}>
					<DownloadIcon className="fill-bright-blue" />
				</button>
			)}
			<Modal
				isOpen={modalIsOpen}
				contentLabel={t("report.generate")}
				className={isGenerateDisable ? "report-modal progress" : "report-modal"}
				ariaHideApp={false}
			>
				<div className="report-modal-box">
					<div className="report-modal-title">
						<h2>{t("report.generate")}</h2>
						<div className="close">
							<CloseIcon onClick={closeReportSelection} className="hover:cursor-pointer" />
						</div>
					</div>
					<form className="report-form">
						<h3>{t("report.type")}</h3>
						<div className="report-list">
							<Select
								options={reports}
								placeholder=" "
								onOptionChange={onOptionChange}
								getOptionLabel={getOptionLabel}
								getOptionValue={getOptionValue}
							/>
						</div>
						<div className="report-subform">{children}</div>
					</form>
					<div className="report-buttons">
						<button className="tertiary-bordered-grey" onClick={closeReportSelection}>
							{t("report.close")}
						</button>
						<button
							className={isGenerateDisable ? "disable" : "primary"}
							onClick={generateReport}
							disabled={isGenerateDisable}
						>
							{t("report.generate")}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};
