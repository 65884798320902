import React, { FC, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./PdfViewer.css";

interface IPdfViewerProps {
	file: any;
}

export const PdfViewer: FC<IPdfViewerProps> = (props: IPdfViewerProps) => {
	const maxWidth = 800;
	const [numPages, setNumPages] = useState<number>(1);

	const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy): void => {
		setNumPages(pdf.numPages);
	};

	return (
		<>
			<center>
				<div className="pdf-container">
					{/* That is an external library that needs any as a prop*/}
					{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
					<Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
						{Array.from(new Array(numPages), (el, index) => (
							<Page key={`doc-${index + 1}`} pageNumber={index + 1} width={maxWidth} />
						))}
					</Document>
				</div>
			</center>
		</>
	);
};
