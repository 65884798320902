import React, { FC, useRef, useState } from "react";
import { v4 } from "uuid";
import { IBasicProps } from "../IBasicProps";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import { ContractCreatorAttachment } from "./model/ContractCreatorAttachment";
import { DocumentUploader } from "./DocumentUploader";
import { FileTab } from "./FileTab";
import "./DocumentsUploader.css";

interface IDocumentUploaderProps extends IBasicProps {
	attachments: ContractCreatorAttachment[];
	setAttachments: (files: ContractCreatorAttachment[] | ((prevAtt: ContractCreatorAttachment[]) => any[])) => void;
	disabled?: boolean;
	downloadAllFiles: () => void;
	areDocumentsDownloadedFromServer: boolean;
}

export const DocumentsUploader: FC<IDocumentUploaderProps> = (props: IDocumentUploaderProps) => {
	const [isIncorrectAttachmentName, setIsIncorrectAttachmentName] = useState(false);
	const [incorrectAttachmentNameReason, setIncorrectAttachmentNameReason] = useState("");

	const isInvalidFileName = (file: File | undefined): boolean =>
		file?.name.startsWith(".") ||
		(file !== undefined && (file.name.split(".").length - 1 > 1 || file.name.includes(" ")));

	const addAttachment = (key: string, value?: File): void => {
		if (isInvalidFileName(value)) {
			setIncorrectAttachmentNameReason(props.t("invalid.document.name"));
			setIsIncorrectAttachmentName(true);
			return;
		}

		if (value?.name.length === 0) {
			setIncorrectAttachmentNameReason(props.t("empty.document.name"));
			setIsIncorrectAttachmentName(true);
			return;
		}

		if (value !== undefined && value.type === "application/pdf") {
			setIncorrectAttachmentNameReason("");
			setIsIncorrectAttachmentName(false);
			const newFile = new ContractCreatorAttachment(key, value);
			props.setAttachments(prevAtt => [...prevAtt, newFile]);
		}
	};

	const addMultipleAttachments = (files: FileList): void => {
		for (const file of files) {
			addAttachment(v4(), file);
		}
	};

	const removeAttachment = (key: string): void => {
		const newArr = [...props.attachments];
		const attachmentToDelete = props.attachments.filter(att => att.key === key);
		const index = props.attachments.indexOf(attachmentToDelete[0], 0);
		if (index > -1) {
			newArr.splice(index, 1);
		}

		props.setAttachments(newArr);
	};

	const inputAttachment = useRef<HTMLInputElement | null>(null);
	const attachmentClick = (): void => {
		if (inputAttachment.current !== null) {
			inputAttachment.current.click();
		}
	};

	const isDownloadAllowed = (): boolean =>
		props.disabled !== undefined && props.disabled && props.areDocumentsDownloadedFromServer;

	return (
		<>
			<input
				type="file"
				id="attachmentInput"
				ref={inputAttachment}
				style={{ display: "none" }}
				onChange={e => addAttachment(v4(), e.target.files?.[0])}
				disabled={props.disabled}
			/>
			<>
				{!props.disabled ? (
					<DocumentUploader
						name={props.t("attachment")}
						onClick={() => attachmentClick()}
						onDrop={e => {
							addMultipleAttachments(e.dataTransfer.files);
							e.preventDefault();
						}}
						{...props}
					/>
				) : !props.areDocumentsDownloadedFromServer ? (
					<div>{props.t("loading.documents")}</div>
				) : (
					props.attachments?.length > 0 && <div className="mt-2">{props.t("attachment")}</div>
				)}
				{isIncorrectAttachmentName && <span className="text-red">{incorrectAttachmentNameReason}</span>}
				<ul className="list-none p-0">
					{props.attachments.map((att, index) => (
						<li className="p-0" key={index}>
							<FileTab name={att.value.name} onBinClick={() => removeAttachment(att.key)} disabled={props.disabled} />
						</li>
					))}
				</ul>
				{isDownloadAllowed() && (
					<div
						className="flex hover:cursor-pointer text-bright-blue w-fit gap-2"
						onClick={() => props.downloadAllFiles()}
					>
						<span>{props.t("download.all")}</span>
						<DownloadIcon className="fill-bright-blue" />
					</div>
				)}
			</>
		</>
	);
};
