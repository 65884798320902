import React, { FC, useEffect, useState } from "react";
import { PdfViewer } from "../../pdfviewer/PdfViewer";
import { fetchFile } from "../../file/downloadFileService";
import { ContractDetailsProjection } from "./model/ContractDetailsProjection";
import { AttachmentTab } from "./AttachmentTab";

interface IDocumentsViewerProps {
	contractDetailsProjection: ContractDetailsProjection;
	getFileName: (name: string) => string;
	translate: (key: string) => string;
}

export const DocumentsViewer: FC<IDocumentsViewerProps> = props => {
	const [pdfFile, setPdfFile] = useState<Blob>();
	const [activePdfId, setActivePdfId] = useState<string>("");

	useEffect(() => {
		if (props.contractDetailsProjection.mainDocument) {
			displayPdf(
				props.contractDetailsProjection.mainDocument.id,
				props.contractDetailsProjection.mainDocument.contentType
			);
		}
	}, []);

	const displayPdf = (id: string, contentType: string): void => {
		fetchFile(id, contentType)
			.then(r => {
				setPdfFile(r);
				setActivePdfId(id);
			})
			.catch(error => console.log(error));
	};

	return (
		<div className="contract-details-pdf-container">
			<div className="flex">
				{props.contractDetailsProjection.mainDocument !== null && (
					<AttachmentTab
						name={props.getFileName(props.contractDetailsProjection.mainDocument.name)}
						pdfId={props.contractDetailsProjection.mainDocument.id}
						displayPdf={() =>
							displayPdf(
								props.contractDetailsProjection.mainDocument.id,
								props.contractDetailsProjection.mainDocument.contentType
							)
						}
						activePdfId={activePdfId}
						translate={props.translate}
					/>
				)}
				{props.contractDetailsProjection.attachments?.map((attachment, index) => (
					<AttachmentTab
						name={props.getFileName(attachment.name)}
						pdfId={attachment.id}
						key={`doc-${index + 1}`}
						displayPdf={() => displayPdf(attachment.id, attachment.contentType)}
						activePdfId={activePdfId}
						index={index + 1}
						translate={props.translate}
					/>
				))}
			</div>
			{/* It uses external library that need any as a prop*/}
			{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
			{pdfFile && <PdfViewer file={pdfFile} />}
		</div>
	);
};
