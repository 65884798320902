import React, { FC, useMemo, useState } from "react";
import moment from "moment/moment";
import { IBasicProps } from "../../IBasicProps";
import { AccessControl } from "../../accessControl/AccessControl";
import { ContractDetailsProjection } from "./model/ContractDetailsProjection";
import { ContractBackButton } from "./ContractBackButton";
import { ContractDetailsButtons } from "./ContractDetailsButtons";
import { ContractDetailsCheckbox, toContractDetailsCheckboxList } from "./model/ContractDetailsCheckbox";
import { ContractCheckboxes } from "./ContractCheckboxes";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

interface IContractActionsContainerProps extends IBasicProps {
	checkboxes: string[];
	contract: ContractDetailsProjection;
	currentContractId?: string;
	userId?: string;
	isQuestionnaireView?: boolean;
	areAllRequiredDocumentsDownloaded: () => boolean;
	onFetchContractDetails: () => void;
	showGeneratePdfLoader: (value: boolean) => void;
}

export const ContractActionsContainer: FC<IContractActionsContainerProps> = props => {
	const [showCheckboxesForRecertification, setShowCheckboxesForRecertification] = useState(false);
	const [showCheckboxesCheckingRequiredWarning, setShowCheckboxesCheckingRequiredWarning] = useState(false);
	const [contractDetailsCheckboxes, setContractDetailsCheckboxes] = useState<ContractDetailsCheckbox[]>([]);

	useMemo(() => {
		if (props.checkboxes && props.checkboxes.length > 0) {
			setContractDetailsCheckboxes(toContractDetailsCheckboxList(props.checkboxes));
			if (
				props.contract.recertificationState === "PROCESSING" &&
				(props.contract.approvalState === "CONFIRMED" || props.contract.approvalState === "EXPIRED")
			) {
				setShowCheckboxesForRecertification(true);
			}
		}
	}, [props.checkboxes]);

	const shouldDisplayRecertifyButton = (): boolean =>
		props.currentContractId !== undefined &&
		props.contract.allowRecertification !== undefined &&
		props.contract.allowRecertification &&
		(props.contract.approvalState === "CONFIRMED" || props.contract.approvalState === "EXPIRED") &&
		props.contract.releaseState === "RELEASED" &&
		validRecertifyPeriod();

	const shouldDisplayButtonForNotConfirmedOrRevokedContract = (): boolean =>
		(props.contract.approvalState === "NOT_CONFIRMED" || props.contract.approvalState === "REVOKED") &&
		props.contract.releaseState !== "INACTIVE_DEADLINE";

	const validRecertifyPeriod = (): boolean =>
		!props.contract.recertificationPeriod ||
		moment().isBetween(
			moment(props.contract.recertificationPeriodStart, DATE_FORMAT),
			moment(props.contract.recertificationPeriodEnd, DATE_FORMAT)
		);

	const areAllCheckboxesChecked = (): boolean => {
		let areChecked = true;
		contractDetailsCheckboxes.forEach(ch => {
			if (!ch.isChecked) {
				areChecked = false;
			}
		});

		setShowCheckboxesCheckingRequiredWarning(!areChecked);
		return areChecked;
	};

	return (
		<div className="flex mb-5 flex-wrap h-full place-items-start place-content-end">
			<div className="grow w-full h-full">
				<AccessControl allowedPermission={["financialAdvisor"]} {...props}>
					{(shouldDisplayButtonForNotConfirmedOrRevokedContract() ||
						(showCheckboxesForRecertification && shouldDisplayRecertifyButton())) &&
						!props.isQuestionnaireView && (
							<ContractCheckboxes
								contractDetailsCheckboxes={contractDetailsCheckboxes}
								setContractDetailsCheckboxes={setContractDetailsCheckboxes}
								setShowCheckboxesCheckingRequiredWarning={setShowCheckboxesCheckingRequiredWarning}
								showCheckboxesCheckingRequiredWarning={showCheckboxesCheckingRequiredWarning}
								{...props}
							/>
						)}
				</AccessControl>
				<AccessControl allowedPermission={["superAdmin"]} {...props}>
					{props.contract.approvalState === "NOT_CONFIRMED" && !props.isQuestionnaireView && (
						<div className="flex-col text-base text-justify">
							{contractDetailsCheckboxes.map((checkbox, index) => (
								<div key={`con-det-checkbox-${index + 1}`} className="mb-1">
									<label className="hover:cursor-pointer flex items-start">
										<input type="checkbox" className="w-4 hover:cursor-pointer" />
										<span>{checkbox.value}</span>
									</label>
								</div>
							))}
						</div>
					)}
				</AccessControl>
			</div>
			<div className="">
				<ContractBackButton
					contractId={props.currentContractId}
					recertificationState={props.contract.recertificationState}
					approvalState={props.contract.approvalState}
					asButton={true}
					{...props}
				/>
			</div>
			<div className="grow">
				{props.contract.mainDocument && (
					<ContractDetailsButtons
						currentContractId={props.currentContractId}
						contractState={props.contract.approvalState}
						revocationPossible={props.contract.revocationPossible}
						cancellationPossible={props.contract.cancellationPossible}
						affectedUserId={props.userId}
						releaseNecessary={props.contract.releaseNecessary}
						isQuestionnaireView={props.isQuestionnaireView}
						recertificationState={props.contract.recertificationState}
						questionnaire={props.contract.questionnaire}
						areAllCheckboxesChecked={areAllCheckboxesChecked}
						setShowCheckboxesForRecertification={setShowCheckboxesForRecertification}
						shouldDisplayRecertifyButton={shouldDisplayRecertifyButton}
						shouldDisplayButtonForNotConfirmedOrRevokedContract={shouldDisplayButtonForNotConfirmedOrRevokedContract}
						{...props}
					/>
				)}
			</div>
		</div>
	);
};
