import React, { FC, useEffect, useState } from "react";
import { IBasicProps } from "../IBasicProps";
import { get } from "../security/secureClient";

interface IStatusChangeButton extends IBasicProps {
	/**
	 * Contract to change
	 */
	contractId: string;
	/**
	 * Expected ReleaseState of contract after change
	 */
	targetState: string;
	/**
	 * Action performed onClick
	 *
	 * @param MouseEventHandler
	 */
	action?: (MouseEventHandler) => void;
	/**
	 * [UI] Button label – key for i18n
	 */
	label?: string;
	/**
	 * [UI] CSS class name for button
	 */
	className?: string;
	/**
	 * [UI] Alternative element to render
	 */
	children: React.ReactNode;
}

/**
 * Security, BE role based component, will be generated only when logged User has rights to perform given status change.
 *
 * @param props
 * @constructor
 */
export const ReleaseStatusChangeButton: FC<IStatusChangeButton> = ({ label = "", ...props }) => {
	const [possible, setPossible] = useState(false);

	useEffect(() => {
		get(`contract/actions/${props.contractId}`)
			.then(data => (data as string[]).map(s => s.toLowerCase()).includes(props.targetState.toLowerCase()))
			.then(b => setPossible(b))
			.catch(err => {
				console.log(err);
				setPossible(false);
			});
	}, []);

	if (!possible) {
		return <></>;
	}

	if (props.children !== undefined) {
		return <>{props.children}</>;
	}

	return (
		<>
			<button className={props.className} onClick={props.action}>
				{props.t(label)}
			</button>
		</>
	);
};
