import { get } from "../security/secureClient";

export const getAllContracts = (searchParams?: IterableIterator<[string, string]>): Promise<any> => {
	const query = {};
	/* eslint-disable */
	if (searchParams !== undefined) {
		for (const q of searchParams) {
			query[q[0]] = q[1] as string;
			/* eslint-enable */
		}
	}
	return get("contracts/", { params: query });
};

export const getSelectedFromParams = (searchParams?: IterableIterator<[string, string]>): string | undefined => {
	/* eslint-disable */
	if (searchParams !== undefined) {
		for (const q of searchParams) {
			if (q[0] === "categorykey") {
				return q[1];
			}
			/* eslint-enable */
		}
	}
	return undefined;
};

export const getAllCategories = (): Promise<any> => get("category");

export const getAllContractsToApprove = (): Promise<any> => get("contracts/to-approve");
