import React, { FC } from "react";
import { ReactComponent as DocumentIcon } from "../../images/document.svg";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";
import "./DocumentTab.css";

interface IDocumentProps {
	name?: string;
	downloadPdf: () => void;
	index?: number;
	translate: (key: string) => string;
}

export const DocumentTab: FC<IDocumentProps> = (props: IDocumentProps) => {
	const getLabel = (): string => {
		const name = props.name?.split("_", 1);
		const suffix = props.index ? `_${props.translate("documents.attachment")}${props.index}` : "";

		return `${name} ${suffix}`;
	};
	return (
		<div className="document-tab-container">
			<div className="flex pl-2 pr-2 items-center justify-between gap-2 truncate">
				<DocumentIcon className="mt-0.5 fill-gray-400 overflow-visible" />
				<span className="pt-1">{getLabel()}</span>
			</div>
			<div className="document-tab-icons-container pl-2">
				<div className="document-tab-icon pt-1" onClick={() => props.downloadPdf()}>
					<DownloadIcon className="mt-0.5 fill-gray-400" />
				</div>
			</div>
		</div>
	);
};
