import React, { FC } from "react";
import { v4 } from "uuid";
import { ReactComponent as PlusIcon } from "../images/plus.svg";
import { IBasicProps } from "../IBasicProps";
import { notifyError } from "../common/notificationService";
import { ContractCreatorCheckbox } from "./model/ContractCreatorCheckbox";
import { CheckboxCreator } from "./CheckboxCreator";

interface ICheckBoxProviderProps extends IBasicProps {
	checkboxes: ContractCreatorCheckbox[];
	setCheckboxes: (values: ContractCreatorCheckbox[]) => void;
	disabled?: boolean;
}

export const CheckboxProvider: FC<ICheckBoxProviderProps> = (props: ICheckBoxProviderProps) => {
	const addCheckbox = (key: string, value: string): void => {
		if (props.checkboxes.length < 20) {
			const newArr = [...props.checkboxes];
			newArr[props.checkboxes.length] = new ContractCreatorCheckbox(key, value);

			props.setCheckboxes(newArr);
		} else {
			notifyError(props.t("too.many.checkboxes.warning"));
		}
	};

	const removeCheckbox = (key: string): void => {
		const newArr = [...props.checkboxes];
		const checkboxToDelete = props.checkboxes.filter(ch => ch.key === key);
		const index = props.checkboxes.indexOf(checkboxToDelete[0], 0);
		if (index > -1) {
			newArr.splice(index, 1);
		}

		props.setCheckboxes(newArr);
	};

	const setValueForGivenCheckbox = (key: string, value: string): void => {
		const newArr = [...props.checkboxes];
		const checkbox = props.checkboxes.filter(ch => ch.key === key);
		const index = props.checkboxes.indexOf(checkbox[0], 0);

		newArr[index] = new ContractCreatorCheckbox(key, value);

		props.setCheckboxes(newArr);
	};

	return (
		<div className="flex flex-col">
			{!(props.disabled && props.checkboxes?.length === 0) && <span>{props.t("checkboxes")}</span>}
			{!props.disabled && (
				<button className="white-with-icon w-36 ml-0" onClick={() => addCheckbox(v4(), "")}>
					<span>{props.t("add.to")}</span>
					<PlusIcon className="fill-bright-blue" />
				</button>
			)}
			<ul className="list-none p-0">
				{props.checkboxes.map((c, index) => (
					<li className="p-0" key={index}>
						<CheckboxCreator
							removeCheckbox={() => removeCheckbox(c.key)}
							checkboxValue={c.value}
							checkboxKey={c.key}
							setValue={setValueForGivenCheckbox}
							disabled={props.disabled}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};
