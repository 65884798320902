import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { IBasicProps } from "../../IBasicProps";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";
import { fetchFile } from "../../file/downloadFileService";
import { capitalize } from "../../utils/CustomFunctions";
import { ImageViewer } from "../../common/ImageViewer";
import { WEB_BASE_URL } from "../../constants";
import { generatePdf } from "../../common/service/contractService";
import { ReactComponent as AlertIcon } from "../../images/alert.svg";
import { ContractState } from "../../contractList/Contract";
import { handleError } from "../../common/notificationService";
import { Loader, LoaderRelative } from "../../common/Loader";
import { ProtectedRoute } from "../../common/ProtectedRoute";

import { Info } from "./Info";
import { DocumentTab } from "./DocumentTab";
import { ContractDetailsProjection } from "./model/ContractDetailsProjection";
import { getContractDetails, getContractDetailsForGivenUser } from "./service/contractDetailsService";
import "./ContractDetails.css";
import { SubscriberInfo } from "./SubscriberInfo";
import { GeneratePdfRequest } from "./model/GeneratePdfRequest";
import { UploadedFileProjection } from "./model/UploadedFileProjection";
import { DocumentsViewer } from "./DocumentsViewer";
import { QuestionnaireViewer } from "./QuestionnaireViewer";
import { ContractActionsContainer } from "./ContractActionsContainer";
import { ContractBackButton } from "./ContractBackButton";
import { QuestionnaireProvider } from "./context/QuestionnaireContext";
import { PdfOptions } from "./model/PdfOptions";

interface IDetailsViewerProps extends IBasicProps {
	contractDetailsProjection: ContractDetailsProjection;
	isCurrentUser?: boolean;
	detailsContractId?: string;
	userId?: string;
	getFileName: (name: string) => string;
	useQuestionnaire?: boolean;
	hasQuestionnaire?: boolean;
	detailsLoaded: boolean;
	approvalState: ContractState;
	onFetchContractDetails: () => void;
	showGeneratePdfLoader: (value: boolean) => void;
}

const DetailsViewer: FC<IDetailsViewerProps> = (props: IDetailsViewerProps) => {
	const [showDownloadRequiredWarning, setShowDownloadRequiredWarning] = useState(false);
	const [downloadedFiles, setDownloadedFiles] = useState<string[]>([]);
	const [previewImage, setPreviewImage] = useState<Blob>(new Blob());
	const [allFiles, setAllFiles] = useState<string[]>([]);

	useEffect(() => {
		let allDocuments: string[] = [];
		if (props.contractDetailsProjection.mainDocument !== null) {
			allDocuments = [props.contractDetailsProjection.mainDocument.id];
		}
		props.contractDetailsProjection.attachments?.map(att => {
			allDocuments.push(att.id);
		});
		setAllFiles(allDocuments);
	}, [props.contractDetailsProjection]);

	useMemo(() => {
		if (
			props.contractDetailsProjection.previewImage !== undefined &&
			props.contractDetailsProjection.previewImage !== null
		) {
			fetchFile(
				props.contractDetailsProjection?.previewImage.id,
				props.contractDetailsProjection?.previewImage.contentType
			)
				.then(r => setPreviewImage(r))
				.catch(error => console.log(error));
		}
	}, []);

	const areAllRequiredDocumentsDownloaded = (): boolean => {
		let areDownloaded = true;
		if (props.contractDetailsProjection?.mandatoryDownload) {
			allFiles.forEach(file => {
				if (!downloadedFiles.includes(file)) {
					areDownloaded = false;
				}
			});
		}
		setShowDownloadRequiredWarning(!areDownloaded);
		return areDownloaded;
	};

	useEffect(() => {
		areAllRequiredDocumentsDownloaded();
	}, [downloadedFiles]);

	const downloadFile = (id: string, contentType: string, name: string): void => {
		const link = document.createElement("a");
		fetchFile(id, contentType)
			.then(r => {
				setDownloadedFiles(prevDownloadedFiles => [...prevDownloadedFiles, id]);
				link.href = URL.createObjectURL(r);
				link.setAttribute("download", name);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch(error => console.log(error));
	};

	const extractAttachementFilename = (attachement: UploadedFileProjection): string => {
		const filenameParts = attachement.name.split(".");
		filenameParts.pop();
		const [, ...contractNumber] = props.contractDetailsProjection.mainDocument.fileName?.split("_");

		return `${filenameParts.join(".")}_${contractNumber?.join("_")}`;
	};

	const downloadAllFiles = (): void => {
		if (props.contractDetailsProjection !== undefined) {
			const mainDocument = props.contractDetailsProjection.mainDocument;
			downloadFile(mainDocument.id, mainDocument.contentType, mainDocument.fileName);

			props.contractDetailsProjection.attachments?.map(attachment => {
				downloadFile(attachment.id, attachment.contentType, extractAttachementFilename(attachment));
			});
		}
	};

	return (
		<div
			className={
				props.useQuestionnaire && props.hasQuestionnaire
					? "contract-details-data-container-questionnaire"
					: "contract-details-data-container"
			}
		>
			{!props.detailsLoaded ? (
				<LoaderRelative size={64} />
			) : (
				<>
					<div className="h-11/12">
						<ContractBackButton
							isQuestionnaireView={props.useQuestionnaire}
							contractId={props.detailsContractId}
							recertificationState={props.contractDetailsProjection.recertificationState}
							asButton={false}
							backLabel={props.contractDetailsProjection.name}
							{...props}
						/>
						<div className="contract-details-description">
							<div className="w-3/12 h-fit">
								<ImageViewer image={previewImage} />
							</div>
							<div className="flex-col ml-6 w-9/12">
								<h4>{props.t("description")}</h4>
								<div dangerouslySetInnerHTML={{ __html: props.contractDetailsProjection.description }}></div>
							</div>
						</div>
						{props.isCurrentUser ? (
							<Info
								state={props.approvalState}
								releaseState={props.contractDetailsProjection.releaseState}
								category={props.contractDetailsProjection.category}
								interval={props.contractDetailsProjection.interval}
								infiniteInterval={props.contractDetailsProjection.infiniteInterval}
								expirationDate={props.contractDetailsProjection.expirationDate}
								isInactive={props.contractDetailsProjection.releaseState === "INACTIVE"}
								{...props}
							/>
						) : (
							<SubscriberInfo
								contractId={props.detailsContractId}
								affectedUserId={props.userId}
								category={props.contractDetailsProjection.category}
								{...props}
							/>
						)}
						{props.contractDetailsProjection.mainDocument !== undefined &&
							props.contractDetailsProjection.mainDocument !== null && (
								<div className="mt-4 mb-16">
									<p>{props.t("documents")}</p>
									<div className="flex-col">
										<DocumentTab
											name={props.getFileName(props.contractDetailsProjection.mainDocument.fileName)}
											downloadPdf={() =>
												downloadFile(
													props.contractDetailsProjection.mainDocument.id,
													props.contractDetailsProjection.mainDocument.contentType,
													props.contractDetailsProjection.mainDocument.fileName
												)
											}
											translate={props.t}
										/>
										{props.contractDetailsProjection.attachments?.map((attachment, index) => (
											<DocumentTab
												key={`doc-${index + 1}`}
												name={props.getFileName(attachment.name)}
												downloadPdf={() =>
													downloadFile(attachment.id, attachment.contentType, extractAttachementFilename(attachment))
												}
												index={index + 1}
												translate={props.t}
											/>
										))}
										<div className="flex text-bright-blue">
											<div className="flex hover:cursor-pointer" onClick={() => downloadAllFiles()}>
												<p>{props.t("download.all")}</p>
												<div className="ml-2 pt-0.5">
													<DownloadIcon className="download-blue" />
												</div>
											</div>
										</div>
										{showDownloadRequiredWarning && (
											<div className="flex text-base justify-center items-center gap-2 w-fit">
												<AlertIcon fill="red" className="h-4" />
												<span>{props.t("documents.download.required.warning")}</span>
											</div>
										)}
									</div>
								</div>
							)}
					</div>
					<ContractActionsContainer
						checkboxes={props.contractDetailsProjection.checkboxes}
						currentContractId={props.detailsContractId}
						contract={props.contractDetailsProjection}
						isQuestionnaireView={props.useQuestionnaire && props.hasQuestionnaire}
						areAllRequiredDocumentsDownloaded={areAllRequiredDocumentsDownloaded}
						{...props}
					/>
				</>
			)}
		</div>
	);
};

interface IContractDetailsProps extends IBasicProps {
	isCurrentUser?: boolean;
	isQuestionnaire?: boolean;
}

export const ContractDetails: FC<IContractDetailsProps> = (props: IContractDetailsProps) => {
	const { t } = useTranslation();
	const { contractId, userId } = useParams();
	const [contractDetailsProjection, setContractDetailsProjection] = useState<ContractDetailsProjection>();
	const [detailsLoaded, setDetailsLoaded] = useState(false);
	const [showGeneratePdfLoader, setShowGeneratePdfLoader] = useState(false);
	const [canDisplayPage, setCanDisplayPage] = useState(false);
	const [message, setMessage] = useState("");
	const [fetchingDetailsFailed, setFetchingDetailsFailed] = useState(false);
	const [hasQuestionnaireFields, setHasQuestionnaireFields] = useState(false);
	const [pdfGenerated, setPdfGenerated] = useState(false);

	if (!props.authenticated) {
		const parts = window.location.href.split(WEB_BASE_URL);
		return <Navigate to={`${WEB_BASE_URL}sso?redirect=${parts[1]}`} replace={true} />;
	}

	const canFetchOwnDetails = contractId && props.isCurrentUser;
	const canFetchOtherUserDetails =
		contractId && !props.isCurrentUser && ["superAdmin", "confirmationAdmin"].includes(props.currentUserRole as string);

	const fetchContractDetails = (): void => {
		setContractDetailsProjection(undefined);
		if (canFetchOwnDetails) {
			getContractDetails(contractId)
				.then(r => {
					setContractDetailsProjection(r);
					setDetailsLoaded(true);
				})
				.catch(error => {
					setFetchingDetailsFailed(true);
					console.log(error);
				});
		} else if (canFetchOtherUserDetails) {
			getContractDetailsForGivenUser(contractId, userId)
				.then(r => {
					setContractDetailsProjection(r);
					setDetailsLoaded(true);
				})
				.catch(error => {
					setFetchingDetailsFailed(true);
					console.log(error);
				});
		}
	};
	const generatePdfFromDetails = (contractDetails: ContractDetailsProjection): void => {
		if (contractDetails && contractId) {
			setShowGeneratePdfLoader(true);
			generatePdf(
				contractId,
				new GeneratePdfRequest(contractDetails.questionnaire?.reportKey, []),
				new PdfOptions(false)
			)
				.then(() => {
					fetchContractDetails();
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/details`);
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				})
				.finally(() => {
					setShowGeneratePdfLoader(false);
				});
		}
	};

	const canDisplayDetails = (): void => {
		if (fetchingDetailsFailed) {
			setMessage(props.t("contract.not.found"));
			setCanDisplayPage(false);
			return;
		}

		const shouldNotDisplayDetailsForDynamicContract = (): boolean =>
			props.isQuestionnaire !== undefined &&
			props.isQuestionnaire &&
			contractDetailsProjection?.releaseState !== "TO_BE_RELEASED" &&
			contractDetailsProjection?.releaseState !== "COMMISSIONED";

		switch (props.currentUserRole) {
			case "financialAdvisor": {
				if (contractDetailsProjection?.approvalState === "BEING_REVIEWED") {
					setMessage(props.t("contract.under.review.message"));
					setCanDisplayPage(false);
				} else {
					setCanDisplayPage(true);
				}

				break;
			}

			case "superAdmin": {
				if (shouldNotDisplayDetailsForDynamicContract()) {
					setMessage(props.t("contract.already.released"));
					setCanDisplayPage(false);
				} else {
					setCanDisplayPage(true);
				}

				break;
			}

			case "editor": {
				if (shouldNotDisplayDetailsForDynamicContract()) {
					setMessage(props.t("contract.already.released"));
					setCanDisplayPage(false);
				} else {
					setCanDisplayPage(true);
				}

				break;
			}

			case "releaseAdmin": {
				if (shouldNotDisplayDetailsForDynamicContract()) {
					setMessage(props.t("contract.already.released"));
					setCanDisplayPage(false);
				} else {
					setCanDisplayPage(true);
				}

				break;
			}

			case undefined: {
				setCanDisplayPage(false);
				break;
			}

			default:
				setCanDisplayPage(true);
				break;
		}
	};

	useEffect(() => {
		fetchContractDetails();
	}, []);

	useMemo(() => {
		canDisplayDetails();
	}, [contractDetailsProjection, fetchingDetailsFailed]);

	useEffect(() => {
		if (contractDetailsProjection) {
			const hasQuest =
				contractDetailsProjection.questionnaire?.fields && contractDetailsProjection.questionnaire?.fields.length > 0;
			setHasQuestionnaireFields(hasQuest);
			if (!contractDetailsProjection.approvalState || contractDetailsProjection.approvalState !== "CONFIRMED") {
				if (
					!hasQuest &&
					!pdfGenerated &&
					canDisplayPage &&
					["financialAdvisor", "superAdmin"].includes(props.currentUserRole as string)
				) {
					setPdfGenerated(true);
					generatePdfFromDetails(contractDetailsProjection);
				}
			}
		}
	}, [contractDetailsProjection]);

	const getFileName = (fileName: string): string => capitalize(fileName.substring(0, fileName.indexOf(".")));

	return (
		<>
			{!detailsLoaded ? (
				<div className="flex items-center justify-center h-full">
					<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
				</div>
			) : (
				<ProtectedRoute condition={canDisplayPage} message={message} {...props}>
					<QuestionnaireProvider>
						{showGeneratePdfLoader && <Loader size={64} coverBackground={true} />}
						{contractDetailsProjection ? (
							<div className="contract-details-main-container">
								<DetailsViewer
									contractDetailsProjection={contractDetailsProjection}
									detailsContractId={contractId}
									userId={userId}
									getFileName={getFileName}
									useQuestionnaire={props.isQuestionnaire}
									hasQuestionnaire={hasQuestionnaireFields}
									detailsLoaded={detailsLoaded}
									approvalState={contractDetailsProjection.approvalState}
									onFetchContractDetails={fetchContractDetails}
									showGeneratePdfLoader={setShowGeneratePdfLoader}
									{...props}
								/>
								{!props.isQuestionnaire && (
									<DocumentsViewer
										contractDetailsProjection={contractDetailsProjection}
										getFileName={getFileName}
										translate={t}
									/>
								)}
								{props.isQuestionnaire && hasQuestionnaireFields && (
									<QuestionnaireViewer contractId={contractId} fetchContractDetails={fetchContractDetails} {...props} />
								)}
							</div>
						) : (
							fetchingDetailsFailed && (
								<div className="flex flex-col items-center pt-20 gap-4">
									<span className="text-xl text-center">{t("contract.not.found")}</span>
									<button className="primary h-8" onClick={() => props.navigate(`${WEB_BASE_URL}`)}>
										{t("back.to.homepage")}
									</button>
								</div>
							)
						)}
					</QuestionnaireProvider>
				</ProtectedRoute>
			)}
		</>
	);
};
